import React, { Fragment, useRef, useState, useEffect, useContext } from "react";
import { supabase } from "../../lib/supabase";
import { UserContext } from "../../App";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../inputs/Button";
import { PayPalButtons } from "@paypal/react-paypal-js";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Checkout = ({ data, setOpen }) => {
  const { user } = useContext(UserContext);
  const [clientSecret, setClientSecret] = useState(null);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-300">
        Checkout
      </Dialog.Title>

      <PayPalButtons
        style={{ layout: "horizontal", color: "silver", shape: "rect" }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: "P-06T34192D0381103UMRESPNA",
          }).then((orderId => {
              console.log(orderId)
              return orderId
            })
          )
          
        }}
        onApprove={(data, actions) => {
          console.log(data)
          console.log(actions)
          return actions.subscription.get().then( async function(details) {
            console.log(details)
            alert('Subscription completed');
            // save the subscription details to your database and associate it with the user's record
            const {data, error} = await supabase
              .from('profiles')
              .update({ subscription_id: details.id, pro_level: 1, pro_date: details.create_time })
              .eq('id', user.id)
            if (error) {
              console.log(error)
            }
          });
        }}
        onError={(err) => {
          console.log(err)
        }}
        onCancel={(data) => {
          console.log(data)
        }}
      >

      </PayPalButtons>
    </>
  );
};

export default Checkout;
