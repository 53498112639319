import React from 'react'
import AssetCard from '../../components/cards/AssetCard'
import Spinner from '../../components/Spinner'

const AssetsResults = (props) => {
  const { loading, results } = props
  return (
    <div className="max-w-[1400px] gap-4">
      {loading && results.length === 0 ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {results.map((result, index) => {
            return (
              <AssetCard asset={result} showDetails={true}/>
            );
          })}
        </div>
      )}
    </div>
  )
}

export default AssetsResults