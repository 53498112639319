import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from "../App";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import video from '../assets/images/background.mov'
import Button from './inputs/Button'

const Hero = () => {
    const navigate = useNavigate();
    const { user, profile, logIn } = useContext(UserContext);

    if ( user ) {
        return (
            <div className="relative lg:mx-4 2xl:mx-[32px] overflow-hidden bg-hero-image py-8 -mt-16 rounded-b-2xl h-[500px] bg-center bg-no-repeat bg-cover">
                <video className="absolute top-0 2xl:-top-48 object-center object-cover min-w-full min-h-full" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-t from-gray-800/70 to-zinc-900/90">
                    <div className="py-20 px-6 sm:px-6 sm:py-24 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-6xl font-bold tracking-tight text-white sm:text-7xl">
                                <span className='text-transparent bg-clip-text bg-blue-500'>Welcome, </span>{profile?.name}
                            </h2>
                        </div>
                        {/* news carousel */}
                        <div className="">
                            <Carousel 
                                className="max-w-2xl mx-auto"
                                infiniteLoop
                                showStatus={false}
                                autoPlay
                                interval={6500}
                                showArrows={false}
                            >
                                <div>
                                    <div className="bg-zinc-900/80 border border-white/10 rounded-lg h-48 mx-8 my-10 py-4 px-6 text-left hover:cursor-pointer hover:scale-105 transition">
                                        <h3 className="text-2xl font-bold">FiveBoard Launch!</h3>
                                        <p className="text-zinc-400 truncate">
                                            Welcome to FiveBoard! We hope you enjoy!
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-zinc-900/80 border border-white/10 rounded-lg h-48 mx-8 my-10 py-4 px-6 text-left hover:cursor-pointer hover:scale-105 transition">
                                        <h3 className="text-2xl font-bold">Best Servers of July</h3>
                                        <p className="text-zinc-400 truncate">
                                            Welcome to FiveBoard! We hope you enjoy!
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-zinc-900/80 border border-white/10 rounded-lg h-48 mx-8 my-10 py-4 px-6 text-left hover:cursor-pointer hover:scale-105 transition">
                                        <h3 className="text-2xl font-bold">Best Assets of July</h3>
                                        <p className="text-zinc-400 truncate">
                                            Welcome to FiveBoard! We hope you enjoy!
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div className="bg-zinc-900/80 border border-white/10 rounded-lg h-48 mx-8 my-10 py-4 px-6 text-left hover:cursor-pointer hover:scale-105 transition">
                                        <h3 className="text-2xl font-bold">Best Assets of July</h3>
                                        <p className="text-zinc-400 truncate">
                                            Welcome to FiveBoard! We hope you enjoy!
                                        </p>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="relative lg:mx-4 2xl:mx-[32px] overflow-hidden bg-hero-image py-8 -mt-16 rounded-b-2xl h-[600px] bg-center bg-no-repeat bg-cover">
                <video className="absolute top-0 2xl:-top-48 object-center object-cover min-w-full min-h-full" autoPlay loop muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="absolute top-0 right-0 bottom-0 left-0 bg-gradient-to-t from-gray-800/70 to-zinc-900/90">
                    <div className="py-16 px-6 sm:px-6 sm:py-32 lg:px-8">
                        <div className="mx-auto max-w-2xl text-center">
                            <h2 className="text-6xl font-bold tracking-tight text-white sm:text-7xl">
                                <span className='text-transparent bg-clip-text bg-blue-500'>Supercharge</span>
                                <br />
                                your server!
                            </h2>
                            <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-zinc-300">
                                FiveBoard helps you find the best vehicle developers who make exactly what you need to make your server stand out.
                            </p>
                            <div className="mt-10 flex items-center justify-center gap-x-6">
                                <Button 
                                    label={user ? "Go to dashboard" : "Get started!"}
                                    onClick={() => {
                                        if (user) {
                                            navigate(`dev/${profile.handle}`)
                                        } else {
                                            logIn()
                                        }
                                    }}
                                />
                                <a href="#" className="text-sm font-semibold leading-6 text-white">
                                    Learn more <span aria-hidden="true">→</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    
}

export default Hero