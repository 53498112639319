import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./inputs/Button";
import EditProfile from "./modals/EditProfile";
import EditServer from "./modals/EditServer";
import NewServer from "./modals/NewServer";
import NewAsset from "./modals/NewAsset";
import EditAsset from "./modals/EditAsset";
import Checkout from "./modals/Checkout";

const Modal = ({ openModal, isOpen, setOpen, type, data }) => {
  const cancelButtonRef = useRef(null);

  const content = (type) => {
    switch(type) {
      case "editProfile":
        return <EditProfile openModal={openModal} setOpen={setOpen} data={data}></EditProfile>;
        break;

      case "newServer":
        return <NewServer openModal={openModal} setOpen={setOpen} data={data}></NewServer>;
        break;

      case "editServer":
        return <EditServer openModal={openModal} setOpen={setOpen} data={data}></EditServer>;
        break;

      case "newAsset":
        return <NewAsset openModal={openModal} setOpen={setOpen} data={data}></NewAsset>;
        break;

      case "editAsset":
        return <EditAsset openModal={openModal} setOpen={setOpen} data={data}></EditAsset>;
        break;

      case "checkout":
        return <Checkout openModal={openModal} setOpen={setOpen} data={data}></Checkout>;
        break;
      
      default:
        return <div>Modal not found</div>;
        break;
    }
      
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-zinc-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        {/* Dialog */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Dialog.Panel className="relative transform rounded-lg bg-zinc-800 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">

                {content(type)}

              </Dialog.Panel>
            </div>
          </div>
        </Transition.Child>
        
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
