export default function Example({type, name, value, placeholder, withAt, onChange}) {
  return (
    <div>
      <label htmlFor={type} className="block text-sm font-medium leading-6 text-zinc-400">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm text-zinc-500">
        {withAt ? (
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
              <path fillRule="evenodd" d="M5.404 14.596A6.5 6.5 0 1116.5 10a1.25 1.25 0 01-2.5 0 4 4 0 10-.571 2.06A2.75 2.75 0 0018 10a8 8 0 10-2.343 5.657.75.75 0 00-1.06-1.06 6.5 6.5 0 01-9.193 0zM10 7.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5z" clipRule="evenodd" />
            </svg>
          </div>
        ) : null}
        
        <input
          value={value}
          type={type}
          name={name}
          id={name}
          // TODO this is ugly when focused for some unknown reason
          className="block w-full bg-zinc-700 rounded-md border-0 py-1.5 pl-4 text-zinc-50 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-zinc-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  )
}
