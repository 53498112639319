import React from 'react'

const TagButton = ({showUses, tag, selected, selectTag, deselectTag}) => {

  function handleClick() {
    if (selected) {
      console.log("Tag is already selected")
      deselectTag(tag)
    } else {
      console.log("Tag is not selected")
      selectTag(tag)
    }
  }

  return (
    <>
    <button 
      className={`border-2 rounded-md border-zinc-600 px-2 py-1 hover:brightness-105 hover:border-zinc-500 ${selected ? "bg-zinc-600 text-white" : "text-zinc-400"}`}
      onClick={() => handleClick()}
    >
      <div className="flex gap-1">
        <div className="font-semibold">{tag.tag}</div> 
        {showUses ? (<div className={`inline ${selected ? 'text-zinc-400' : 'text-zinc-600'}`}>({tag.uses})</div>) : (null)}
      </div>
    </button>
      
    </>
  )
}

export default TagButton