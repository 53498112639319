import React from "react";

const NotFound = () => {
  return (
    <div className="grid min-h-screen grid-cols-1 grid-rows-[1fr,auto,1fr] bg-zinc-900 lg:grid-cols-[max(50%,36rem),1fr]">
      <header className="mx-auto w-full max-w-[1400px] px-6 pt-6 sm:pt-10 lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:px-8">
        <a href="/">
          <span className="sr-only">Your Company</span>
          <img
            className="h-10 w-auto sm:h-12"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt=""
          />
        </a>
      </header>
      <main className="mx-auto w-full max-w-[1400px] px-6 py-24 sm:py-32 lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:px-8">
        <div className="max-w-lg">
          <p className="text-base font-semibold leading-8 text-indigo-300">
            404
          </p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-zinc-300 sm:text-5xl">
            Page not found
          </h1>
          <p className="mt-6 text-base leading-7 text-zinc-400">
            You found a secret page! <br />
            Unfortunately, we don't have anything to show you here.
          </p>
          <div className="mt-10">
            <a
              href="/"
              className="text-sm font-semibold leading-7 text-indigo-300"
            >
              <span aria-hidden="true">&larr;</span> Back to home
            </a>
          </div>
        </div>
      </main>
      <footer className="self-end lg:col-span-2 lg:col-start-1 lg:row-start-3">
        <div className="border-t border-zinc-700 bg-zinc-800 py-10">
          <nav className="mx-auto flex w-full max-w-[1400px] items-center gap-x-4 px-6 text-sm leading-7 text-zinc-600 lg:px-8">
            <a href="#">Contact support</a>
            <svg
              viewBox="0 0 2 2"
              aria-hidden="true"
              className="h-0.5 w-0.5 fill-zinc-300"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            <a href="#">Status</a>
            <svg
              viewBox="0 0 2 2"
              aria-hidden="true"
              className="h-0.5 w-0.5 fill-zinc-300"
            >
              <circle cx={1} cy={1} r={1} />
            </svg>
            <a href="#">Twitter</a>
          </nav>
        </div>
      </footer>
      <div className="hidden lg:relative lg:col-start-2 lg:row-start-1 lg:row-end-4 lg:block">
        <img
          src="https://images.unsplash.com/photo-1470847355775-e0e3c35a9a2c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1825&q=80"
          alt=""
          className="absolute inset-0 h-full w-full object-cover"
        />
      </div>
    </div>
  );
};

export default NotFound;
