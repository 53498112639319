import TagButton from './TagButton'

const TagCloud = ({type, selectedTags, unselectedTags, setSelectedTags, setUnselectedTags, showUses, count, max, noLabel}) => {

  // console.log(selectedTags)

  function selectTag(tag) {
    if (!max || (selectedTags.length < max)) {
      // add the tag to selectedTags
      setSelectedTags([...selectedTags, tag])
      // remove the tag from unselectedTags
      setUnselectedTags(unselectedTags.filter((t) => t.id !== tag.id))
    } else {
      console.log("Max tags reached")
    }
  }

  function deselectTag(tag) {
    // remove the tag from selectedTags
    setSelectedTags(selectedTags.filter((t) => t.id !== tag.id))
    // add the tag to unselectedTags
    setUnselectedTags([tag, ...unselectedTags])
  }

  const cloudItems = unselectedTags.map((tag, index) => {
    return (
      <TagButton
        showUses={showUses}
        key={index}
        tag={tag}
        selected={false}
        selectTag={selectTag}
      />
    )
  })

  const activeCloudItems = selectedTags.map((tag, index) => {
    return (
      <TagButton
        showUses={showUses}
        key={index}
        tag={tag}
        selected={true}
        deselectTag={deselectTag}
      />
    )
  })

  return (
    <>
      <div className="flex flex-row gap-4 text-sm font-medium leading-6 text-zinc-400">
        {!noLabel && (
          <label htmlFor="comment" className="block">
            Tags
          </label>
        )}

        {count && max && ({count}/{max})}
        
      </div>


      <div className={`flex flex-wrap gap-2 w-full ${!noLabel ? "mt-2" : ""}`}>
        {activeCloudItems}
        {cloudItems}
      </div>
      
    </>
   
    
  )
}

export default TagCloud