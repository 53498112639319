import React, { useContext } from 'react'
import { UserContext, ModalContext } from '../../../App'
import Tag from '../../../components/Tag'

const ProfileSection = ({dev}) => {
  // context
  const { openModal } = useContext(ModalContext)

  const tags = dev ? dev.tags.map((tag, index) => {
    return <Tag key={index}>{tag}</Tag>
  }) : null

  return (
    <div className="bg-zinc-800 rounded-lg">
      <div className="flex flex-col items-center px-8 py-4 max-w-2xl mx-auto">
        {/* Add banner for Pro users */}
        {/* Icon */}
        <div className="h-36 aspect-square">
          { dev ? (<img  className="rounded-full" src={dev.avatar_url + "?size=256"} />) : (<div className="bg-zinc-700 w-full h-full rounded-full animate-pulse"/>)}
        </div>

        {/* Name */}
        <div>
          { dev ? (
            <h1 className="text-4xl font-bold mt-4">{dev.name}</h1>
          ) : (
            <div className="bg-zinc-700 w-72 h-8 rounded animate-pulse"/>
          )}
        </div>

        {/* Handle */}
        <div>
          { dev ? (
            <p className="text-zinc-400">@{dev.handle}</p>
          ) : (
            <div className="bg-zinc-700 w-32 h-4 rounded animate-pulse"/>
          )}
        </div>

        {/* Tags */}
        { dev ? (
          <div className="flex flex-wrap gap-2 mt-2">
            {tags}
          </div>
        ) : (
          <div className="flex flex-wrap gap-2">
            <div className="h-6 w-12 animate-pulse rounded-full bg-zinc-700" />
            <div className="h-6 w-16 animate-pulse rounded-full bg-zinc-700" />
            <div className="h-6 w-10 animate-pulse rounded-full bg-zinc-700" />
          </div>
        )}

        {/* Bio */}
        { dev ? (
          <p className="text-zinc-400 mt-4">{dev.bio}</p>
        ) : (
          <div className="flex flex-col gap-2 w-full grow">
            <div className="h-4 animate-pulse rounded-full bg-zinc-700" />
            <div className="h-4 animate-pulse rounded-full bg-zinc-700 w-10/12" />
            <div className="h-4 animate-pulse rounded-full bg-zinc-700 w-11/12" />
            <div className="h-4 animate-pulse rounded-full bg-zinc-700 w-1/3" />
          </div>
        )}
        {/* Buttons */}
      </div>
    </div>
  )
}

export default ProfileSection