import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../../lib/supabase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../lib/context/auth";
import { UserContext, ModalContext } from "../../App";
import Button from "../inputs/Button";
import Tag from "../Tag";

//TODO maybe make a wide alternative version instead of using full
const PostCard = ({ full, skeleton, post }) => {
  const navigate = useNavigate();
  const { logIn } = useAuth();
  const [voteLoading, setVoteLoading] = useState(false);
  const [voteDisabled, setVoteDisabled] = useState(false);
  const { user, myVotedServers, myServers } = useContext(UserContext);
  const { openModal } = useContext(ModalContext);

  // needs to be tested
  //console.log(post.owner, user.id);\

  // console.log(post.icon_url)

  let tags = ''
  if (!skeleton) {
    if (post.tags) {
      tags = post.tags.map((tag, index) => {
        return <Tag key={index} color={'zinc'}>{tag}</Tag>;
      });
    }
  }

  // for updating information
  async function updateServer() {
    // if this post address matches an id in myServers, update it and update this post

    

    myServers.forEach(async (server) => {
      if (server.id === post.address) {
        // check if anything is different first
        if (
          server.name === post.name && 
          `https://cdn.discordapp.com/icons/${server.id}/${server.icon}` === post.icon_url && 
          server.approximate_member_count === post.members
        ){
          // console.log("Server info is the same, not updating")
          return
        }
        // if something is different, update it
        console.log("Updating server info...", server)
        const { data, error } = await supabase
          .from("servers")
          .update({
            name: server.name,
            icon_url: `https://cdn.discordapp.com/icons/${server.id}/${server.icon}`,
            members: server.approximate_member_count,
          })
          .eq("address", post.address)
          .select("*")
        if (error) {
          console.log(error);
        } else {
          console.log("Server info updated!", data);
        }
      }
    })
  }


  async function voteServer() {
    setVoteLoading(true);
    const { data, error } = await supabase.functions.invoke("server-vote",{body: { postToVote: post.address }});
    if (error) {
      console.log(error);
    } else if (data.error) {
      console.log(data.error);
      console.log(data.error === "already voted")
      // TODO make a toast system for this and other stuff
      if (data.error === "already voted") alert("You have already voted for this server!");
    } else {
      post.votes += 1;
    }
    setVoteLoading(false);
    setVoteDisabled(true)
  }

  useEffect(() => {
    if (!skeleton) {
      if (myVotedServers.includes(post.address)) {
        setVoteDisabled(true)
      }
    }
  }, [myVotedServers])

  // when component mounts
  useEffect(() => {
    if (post?.owner === user?.id) {
      updateServer();
    }
  });

  if (!skeleton) {
    return (

      // boost colors
      // bg-gradient-to-l from-indigo-500 via-blue-500 to-cyan-500
      // bg-[radial-gradient(ellipse_at_top_right,_var(--tw-gradient-stops))] from-yellow-400 via-white to-yellow-400
      <div
        className={`rounded-xl relative w-full h-64 p-[3px] ${
          post.boosted
            // ? "bg-gradient-to-l from-indigo-500 via-blue-500 to-cyan-500"
            ? "bg-gradient-to-t from-blue-900 to-blue-500"
            : "bg-zinc-600"
        }`}
      >
        <div className={`flex w-full min-h-full flex-col gap-2 overflow-hidden rounded-lg bg-zinc-800/90 p-4 backdrop-blur-2xl shadow-xl hover:brightness-110`}>
          {/* Top */}
          <div className="flex gap-4">
            {/* Icon */}
            <button onClick={() => {navigate(`/server/${post.address}`)}}>
              <img
                alt="Server icon"
                className="aspect-square w-16 rounded-lg bg-zinc-900 font-bold text-zinc-800 max-w-xs"
                src={
                  post.icon_url
                    ? post.icon_url + "?size=128"
                    : "https://i.imgur.com/kIfXEFa.png"
                }
              />
            </button>
            <div className="grid">
              <div
                className="text-left truncate text-2xl font-semibold text-zinc-50 leading-4"
                onClick={() => {
                  navigate(`/server/${post.address}`);
                }}
              >
                {post.name}
              </div>

              {/* TODO hide tags if there are too many by collapsing into "+4" */}
              <div className="flex gap-2 h-fit mt-auto">
                {/* <Tag>{post.category}</Tag> */}
                {tags}
              </div>
            </div>
          </div>

          {/* description */}
          <div className="h-full">
            <p className="select-none text-left text-sm text-zinc-400 line-clamp-4">
              {post.description}
            </p>
          </div>

          {/* buttons + member count*/}
          <div className="flex items-center gap-2 mt-auto">
            <div className="mr-auto mt-1 flex select-none items-center gap-1 justify-self-end text-sm text-zinc-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="h-5 w-5"
              >
                <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z" />
              </svg>
              <p className="font-semibold leading-none">{post.members}</p>
            </div>
            {post.owner === (user ? user.id : null) ? (
              <Button
                secondary
                onClick={() => {
                  openModal("editServer", post);
                }}
                label={
                  <div className="flex items-center gap-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="h-4 w-4"
                    >
                      <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                    </svg>
                    Edit
                  </div>
                }
              />
            ) : (
              <></>
            )}
            <Button
              isLoading = {voteLoading}
              disabled = {voteDisabled}
              secondary
              onClick={() => {
                if (user) {
                  voteServer();
                } else {
                  logIn()
                }
              }}
              label={
                <div className="flex items-center gap-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-5 w-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                      clipRule="evenodd"
                    />
                  </svg>
                  {post.votes}
                </div>
              }
            />
            {post.owner !== (user ? user.id : null) ? (
              <Button onClick={() => {}} label="Join server" />
            ) : post.boosted ? (
              <></>
            ) : (
              <Button
                special
                onClick={() => {
                  navigate("/premium");
                }}
                label="Boost"
              />
            )}
          </div>
        </div>
      </div>
    );
  } else {
    // skeleton
    return (
      <div
        className={`rounded-2xl ${!full ? "sm:aspect-[2/1]" : ""} p-[3px] bg-zinc-600`}
      >
        <div className={`flex w-full ${!full ? "sm:w-[594px]" : "sm:h-[297px]"} min-h-full flex-col gap-4 overflow-hidden rounded-xl bg-zinc-800/90 p-4 backdrop-blur-2xl shadow-xl hover:brightness-110`}>
          {/* Top */}
          <div className="flex gap-4">
            {/* Icon */}
            <div className="w-16 h-16 bg-zinc-700 rounded-lg animate-pulse"/>
            <div className="flex flex-col gap-y-2 pb-1">
              <div className="w-40 h-8 bg-zinc-700 rounded-full animate-pulse"/>

              {/* TODO hide tags if there are too many by collapsing into "+4" */}
              <div className="flex gap-x-2">
                {/* <Tag>{post.category}</Tag> */}
                <div className="w-14 h-6 bg-zinc-700 rounded-full animate-pulse"/>
                <div className="w-14 h-6 bg-zinc-700 rounded-full animate-pulse"/>
                <div className="w-14 h-6 bg-zinc-700 rounded-full animate-pulse"/>
              </div>
            </div>
          </div>

          {/* description */}
          <div className="h-10">
            <div className="w-full h-4 bg-zinc-700 rounded-full animate-pulse mb-2"/>
            <div className="w-full h-4 bg-zinc-700 rounded-full animate-pulse mb-2"/>
            <div className="w-full h-4 bg-zinc-700 rounded-full animate-pulse mb-2"/>
            <div className="w-full h-4 bg-zinc-700 rounded-full animate-pulse mb-2"/>
            <div className="w-1/2 h-4 bg-zinc-700 rounded-full animate-pulse"/>
          </div>

          {/* buttons + member count*/}
          <div className="flex items-center gap-2 mt-auto">
            <div className="mr-auto mt-1 flex select-none items-center gap-1 justify-self-end text-sm text-zinc-400">
              <div className="w-6 h-6 bg-zinc-700 rounded-full animate-pulse"/>
              <div className="w-14 h-6 bg-zinc-700 rounded-full animate-pulse"/>
            </div>
            <Button skeleton />
            <Button skeleton />
          </div>
        </div>
      </div>      
    )
  }
};

export default PostCard;
