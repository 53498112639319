import React from "react";

const Tag = ({ children, color, large, solid }) => {

  solid = solid ? solid : false;

  const colors = {
    "zinc": 'bg-zinc-400/10 text-zinc-400 ring-zinc-400/20',
    "red": 'bg-red-400/10 text-red-400 ring-red-400/20',
    "yellow": 'bg-yellow-400/10 text-yellow-400 ring-yellow-400/20',
    "green": 'bg-green-400/10 text-green-400 ring-green-400/20',
    "blue": 'bg-blue-400/10 text-blue-400 ring-blue-400/20',
    "indigo": 'bg-indigo-400/10 text-indigo-400 ring-indigo-400/20',
    "purple": 'bg-purple-400/10 text-purple-400 ring-purple-400/20',
    "pink": 'bg-pink-400/10 text-pink-400 ring-pink-400/20',
  }

  const colorsSolid = {
    "zinc": 'bg-zinc-500/60 text-zinc-200 ring-zinc-400/90',
    "red": 'bg-red-500/60 text-red-200 ring-red-400/90',
    "yellow": 'bg-yellow-500/60 text-yellow-200 ring-yellow-400/90',
    "green": 'bg-green-500/60 text-green-200 ring-green-400/90',
    "blue": 'bg-blue-500/60 text-blue-200 ring-blue-400/90',
    "indigo": 'bg-indigo-500/60 text-indigo-200 ring-indigo-400/90',
    "purple": 'bg-purple-500/60 text-purple-200 ring-purple-400/90',
    "pink": 'bg-pink-500/60 text-pink-200 ring-pink-400/90',
  }

  // get color string from colors object
  const colorString = colors[color] ? (solid ? colorsSolid : colors)[color] : (solid ? colorsSolid : colors)["zinc"];

  return (
    <div className={`inline-flex items-center rounded-md inner-border-0 ${large ? ('px-4 py-1 text-md') : ('px-2 py-1 text-xs') } font-medium  ring-1 ring-inset ${colorString}`}>
      {!large ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-3 h-3">
          <path fillRule="evenodd" d="M9.493 2.853a.75.75 0 00-1.486-.205L7.545 6H4.198a.75.75 0 000 1.5h3.14l-.69 5H3.302a.75.75 0 000 1.5h3.14l-.435 3.148a.75.75 0 001.486.205L7.955 14h2.986l-.434 3.148a.75.75 0 001.486.205L12.456 14h3.346a.75.75 0 000-1.5h-3.14l.69-5h3.346a.75.75 0 000-1.5h-3.14l.435-3.147a.75.75 0 00-1.486-.205L12.045 6H9.059l.434-3.147zM8.852 7.5l-.69 5h2.986l.69-5H8.852z" clipRule="evenodd" />
        </svg>
      ): null}
      {children}
    </div>
  );
};

export default Tag;
