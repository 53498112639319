import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { supabase } from "../../lib/supabase";
import { getTags } from "../../lib/services/tagsHandler";
import { useDebounce } from "../../lib/hooks/useDebounce";
import Button from "../inputs/Button";
import TextArea from "../inputs/TextArea";
import TextInput from "../inputs/TextInput";
import TagCloud from "../inputs/TagCloud";

// TODO probably better to pass a user id and fetch the data here so it is always up to date
const EditProfile = ({data, setOpen}) => {
  const navigate = useNavigate();

  const { profile, setProfile } = useContext(UserContext);

  const [saving, setSaving] = useState(false)

  const [name, setName] = useState(data.name)
  // const [handleValid, setHandleValid] = useState(true)
  // const [handleDirty, setHandleDirty] = useState(false)
  // const [handleAvailable, setHandleAvailable] = useState(true)
  // const [handleProcessing, setHandleProcessing] = useState(false)
  // const debouncedHandle = useDebounce(handle, 1000);

  const [newBio, setNewBio] = useState(data.bio)
  
  const allTags = getTags("profile")
  const [selectedTags, setSelectedTags] = useState(profile.tags);
  const [unselectedTags, setUnselectedTags] = useState(getTags("profile"));

  // save changes to db
  const saveChanges = async () => {
    setSaving(true)
    // update profile object
    setProfile({
      ...profile,
      name: name,
      bio: newBio,
      tags: tagsOutput,
    });
    // update db
    const { data : saveData, error } = await supabase
      .from("profiles")
      .update({
        name: name,
        bio: newBio,
        tags: tagsOutput,
      })
      .eq("id", data.id);
    if (error) {
      console.log(error);
    } else {
      console.log(data);
    }
    setSaving(false)
  };


  // check if handle is valid
  // const isHandleValid = () => {
  //   // if more less than 2 characters or more than 16
  //   if (handle.length < 2 || handle.length > 16) return false;
  //   // if it contains any special characters except underscore and period
  //   if (handle.match(/[^a-zA-Z0-9_.]/g)) return false;
  //   // if it has any spaces
  //   if (handle.match(/\s/g)) return false;
  //   // if it starts with a period
  //   if (handle.match(/^\./g)) return false;
  //   return true
  // };

  // check if handle is taken
  // const isHandleTaken = async () => {
  //   const { data : handleData, error } = await supabase
  //     .from("profiles")
  //     .select("*")
  //     .eq("handle", handle);
  //   if (error) {
  //     console.log("error", error);
  //   } else {
  //     if (handleData.length > 0) {
  //       if (handle === data.handle) {
  //         return false;
  //       }
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (handleDirty) {
  //     console.log("Handle dirty, checking...")
  //     setHandleDirty(false)

  //     console.log(isHandleValid())
  //     // if handle is valid
  //     if (isHandleValid()) {
  //       console.log("Handle valid, checking if taken...")
  //       setHandleValid(true)
  //       // if handle is not taken
  //       isHandleTaken(debouncedHandle).then((result) => {
  //         if (result) {
  //           // handle taken
  //           console.log("Handle taken")
  //           setHandleAvailable(false)
  //         } else {
  //           // handle available
  //           console.log("Handle available")
  //           setHandleAvailable(true)
  //         }
  //       })
  //     } else {
  //       // handle not valid
  //       console.log("Handle not valid")
  //       setHandleValid(false)
  //     }
  //     setHandleProcessing(false)
  //   }
  // }, [debouncedHandle]);

    const tagsOutput = selectedTags.map((tag) => {
      return `${tag.tag}`
    })
    
    // set selected tags to the current tags
    useEffect(() => {
      if (profile.tags) {
        setSelectedTags([])
        // for each tag in profile, find the tag it matches by the tag field in allTags and add it to selected tags
        profile.tags.forEach((tag) => {
          allTags.forEach((tag2) => {
            if (tag === tag2.tag) {
              setSelectedTags((selectedTags) => [...selectedTags, tag2])
              // remove from unselected tags
              setUnselectedTags((unselectedTags) => unselectedTags.filter((tag3) => tag3.tag !== tag2.tag))
            }
          })
        })
      }
    }, [profile.tags])
  

  return (
    <>
      <div className="flex flex-col gap-2">
        {/* Name */}
        <TextInput type="username" name="name" value={name} onChange={(e) => {
          setName(e.target.value);
          // setHandleDirty(true);
          // setHandleProcessing(true)
        }}/>

        {/* Handle errors, hidden unless conditions are met */}
        {/* <p className="mt-2 text-sm text-red-600" hidden={handleValid}>
          {handle.length < 2 || handle.length > 16
            ? "Handle must be between 2 and 16 characters"
            : null}
          {handle.match(/[^a-zA-Z0-9_.]/g)
            ? "Handle can only contain letters, numbers, underscores, and periods. "
            : null}
          {handle.match(/\s/g) ? "Handle cannot contain spaces. " : null}
          {handle.match(/^\./g) ? "Handle cannot start with a period. " : null}
        </p>
        <p className="mt-2 text-sm text-red-600" hidden={handleAvailable}>
          Handle is already taken
        </p> */}

        {/* Bio */}
        <TextArea rows={3} name="bio" value={newBio} setValue={setNewBio}></TextArea>
        {/* Tags */}
        <TagCloud count={selectedTags.length} max={5} showUses={false} selectedTags={selectedTags} setSelectedTags={setSelectedTags} unselectedTags={unselectedTags} setUnselectedTags={setUnselectedTags}/>
      </div>
      
      

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
        <Button secondary onClick={() => setOpen(false)} label="Cancel">Cancel</Button>
        <Button 
          // disabled={ !handleAvailable || !handleValid } 
          isLoading={
            // handleDirty || handleProcessing || 
            saving
          } 
          onClick={() => {
            saveChanges().then(() => {
              setOpen(false)
            })
          }} 
          label="Save">Save</Button>
      </div>
    </>
  )
}

export default EditProfile