import React from 'react'
import PostCard from '../../components/cards/PostCard'
import Spinner from '../../components/Spinner'

const ServersResults = (props) => {
  const { loading, results } = props
  return (
    <div className="max-w-[1400px] gap-4">
      {loading && results.length === 0 ? (
        <div className="flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {results.map((result, index) => {
            return (
              <PostCard post={result}/>
            );
          })}
        </div>
      )}
    </div>
  )
}

export default ServersResults