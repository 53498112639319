import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ModalContext } from "../App";
import { UserContext } from "../App";
import Header from "./Header";
import Footer from "./Footer";
import Modal from "./Modal";
import ScrollToTop from "./inputs/ScrollToTop";

const Layout = ({ children }) => {
  
  // hide search bar on search page
  const location = useLocation();
  let hideSearch = false
  let hideHeaderFooter = false
  if (location.pathname === "/search/") {
    hideSearch = true;
  } else if (location.pathname === "/beta") {
    hideHeaderFooter = true;
  }
  
  const {
    openModal,
    modalOpen,
    setModalOpen,
    modalType,
    setModalType,
    modalData,
    setModalData,
  } = useContext(ModalContext);
  const { user, profile, setUser, logIn, logOut } = useContext(UserContext);

  return (
    <div className="bg-zinc-900">
      {!hideHeaderFooter && (
        <Header
        user={user}
        profile={profile}
        setUser={setUser}
        logIn={logIn}
        logOut={logOut}
        hideSearch={hideSearch}
      />
    )}

      {children}
      {!hideHeaderFooter && (<Footer />)}
      

      {/* Modal */}
      <Modal
        openModal={openModal}
        isOpen={modalOpen}
        setOpen={setModalOpen}
        type={modalType}
        data={modalData}
      ></Modal>

      {/* back to top button */}
      <ScrollToTop/>
    </div>
  );
};

export default Layout;
