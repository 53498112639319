import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'

const TypeSelector = (props) => {
  const { searchTypes, searchType, setSearchType, setLoadIndex } = props

  const handleChange = (e) => {
    console.log("TypeSelector handleChange:", e)
    setLoadIndex(0)
    setSearchType(e)
  }

  return (
    <div className="relative">
      <label htmlFor="type" />

      <Listbox value={searchType} onChange={handleChange}>

        <Listbox.Button className="relative w-full h-full bg-zinc-700 text-zinc-300 rounded-l-lg px-6 text-left sm:text-xl">
          <span className="block truncate mb-1">{searchType.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-zinc-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute w-full overflow-auto mt-1 rounded-lg">
            {searchTypes.map((option) => (
              <Listbox.Option
              key={option.id}
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-8 pr-4 ${
                  active ? 'bg-zinc-700' : 'bg-zinc-800'
                }`
              }
              value={option}
            >
              <span>
                {option.name}
              </span>
            </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

export default TypeSelector