import react, { useState } from 'react'
import { supabase } from '../../lib/supabase'

const useSearch = (results, setResults) => {

  const itemsToLoad = 2
  const searchTypeMap = new Map([
    ["Servers", "servers"],
    ["Developers", "profiles"],
    ["Assets", "assets"],
  ]);
  const searchVectorMap = new Map([
    ["servers", "server_search_vector"],
    ["profiles", "profile_search_vector"],
    ["assets", "asset_search_vector"],
  ]);

  const [loadingResults, setLoadingResults] = useState(false)
  const [noMoreResults, setNoMoreResults] = useState(false)

  const getSearchVector = (query) => {
    const searchTerms = query.split(" ");
    const searchVector = []
    // Loop through each search term
    searchTerms.forEach((term) => {
      // Add the current term to the search vector array with the appropriate syntax
      searchVector.push(`'${term}'`);
    });
    // Join the search vector array into a single string with the '&' operator
    // TODO see if using || instead of & is better
    const queryString = searchVector.join(" & ");
    return queryString
  }

  const executeSearch = async (query, type, clearResults, loadIndex) => {
    
    const rangeStart = loadIndex * itemsToLoad
    const rangeEnd = (loadIndex * itemsToLoad) + itemsToLoad - 1

    console.group("Executing search")
    console.log("Query:", query)
    console.log("Type:", type)
    console.log("Range:", rangeStart, rangeEnd)
    console.groupEnd()

    let theseResults = []
    setLoadingResults(true)

    // if query is empty string or null search from all results
    if (query === "" || !query) {
      // console.log(`Searching all ${type}...`)
      // do the search for all
      const { data, error } = await supabase
        .from(type)
        .select()
        .range(rangeStart, rangeEnd)
        .order("pro_level", { ascending: false })
        .order("votes", { ascending: false })
      if (error) {
        console.log(error)
      } else {
        theseResults = data
      }
    } else { // if there is a query search with it
      // console.log(`Searching ${type} for ${query}...`)
      // do the search for query
      const searchVector = getSearchVector(query)
      const { data, error } = await supabase
        .from(type)
        .select()
        .textSearch(searchVectorMap.get(type), searchVector)
        .range(rangeStart, rangeEnd)
        .order("pro_level", { ascending: false })
        .order("votes", { ascending: false })
      if (error) {
        // console.log(error)
      } else {
        theseResults = data
      }
    }

    // if clearResults is true, set the results directly
    if (clearResults) {
      console.log("Setting results", theseResults)
      setResults(theseResults)
    } else {
      console.log("Adding results", theseResults)
      setResults([...results, ...theseResults])
    }

    // if returned results length was less than itemsToLoad then there are no more results
    if (theseResults.length < itemsToLoad) {
      // console.log("No more results")
      setNoMoreResults(true)
    } else {
      setNoMoreResults(false)
    }

    setLoadingResults(false)
  }

  return [executeSearch, loadingResults, noMoreResults]
}

export default useSearch