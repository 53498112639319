import { createContext, useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { supabase } from "./lib/supabase";
import { useAuth } from "./lib/context/auth";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

import Layout from "./components/Layout";
import { Home, Search, Premium, Dev, Server, Asset, NotFound, Beta } from "./pages/importMap";

export const UserContext = createContext(null);
export const ModalContext = createContext();

function App() {
  const { user, setUser, session, profile, setProfile, userStatus, logIn, logOut, fetchProfile } = useAuth();
  const [myServers, setMyServers] = useState([]);
  const [myVotedServers, setMyVotedServers] = useState([]);
  const [providerToken, setProviderToken] = useState(null);
  const [myPosts, setMyPosts] = useState(null);
  const updatedProfile = useRef(false);


  async function fetchPosts() {
    //console.log("Fetching posts")
    const { data, error } = await supabase
      .from("servers")
      .select("*")
      .eq("owner", user.id);
    if (error) {
      console.log(error);
    }
    //console.log("Posts", data);
    setMyPosts(data);
  }

  async function fetchUserVotedServerAddresses() {
    //console.log("Fetch votes", user.id)
    const {data, error} = await supabase
      .from("votes")
      .select("*")
      .eq("voter", user.id)
    if (error) {
      console.log(error)
    }
    // remove duplicates from the result
    const uniqueAddresses = [...new Set(data.map((item) => item.for))]
    // console.log(uniqueAddresses)
    setMyVotedServers(uniqueAddresses)
  }

  // Get user servers from discord
  async function getUserServers(token) {
    if (token) {
      // console.log("Getting servers with token:" + token);
      // decrypt the provider token?

      const { data, error } = await supabase.functions.invoke(
        "discord-get-user-servers",
        {
          body: { token: token },
        }
      );
      if (error) {
        console.log(error);
      } else {
        console.log("Owned servers", data);

        // // get my servers from the database
        // const { data: dbServers, error: dbServersError } = await supabase
        //   .from("servers")
        //   .select("*")
        //   .eq("owner", user.id);
        // if (dbServersError) {
        //   console.log(dbServersError);
        // } else {
        //   //console.log("My servers", myServers);
        //   dbServers.forEach(async (dbServer) => {
        //     // update the server with the discord data
        //     const discordServer = data.servers.find((server) => server.id === dbServer.server_id);

        //     if (discordServer) {
        //       //console.log("Found server", dbServer.name, "updating with new info:", discordServer)
        //       const { data, error } = await supabase
        //         .from("servers")
        //         .update({
        //           name: discordServer.name,
        //           icon_url: `https://cdn.discordapp.com/icons/${discordServer.id}/${discordServer.icon}`,
        //           members: discordServer.approximate_member_count,
        //         })
        //         .eq("id", dbServer.id);
        //       if (error) {
        //         console.log(error);
        //       } else {
        //         console.log("Updated server", data);
        //       }
        //     }
        //   });
        // }

        return data;
      }
    } else {
      console.log("Provider token is null for getUserServers");
    }
  }

  useEffect(() => {
    userStatus();
    supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Auth change: " + event);

      // if we signed in, then update the database profile from discord data
      if (event === "SIGNED_IN" && !updatedProfile.current) {
        //console.log("Updating profile")

        // const handleString = (
        //   session.user.identities[0].identity_data.name.slice(0, -5) +
        //   session.user.identities[0].identity_data.name.slice(-4)
        // ).toLowerCase();
        //console.log("Handle from Discord " + handleString)

        // populate user profile with stuff from discord and keep it up to date
        // updating supabase > tables > profiles
        const { error } = await supabase
          .from("profiles")
          .update({
            name: session.user.identities[0].identity_data.full_name,
            handle: session.user.identities[0].identity_data.full_name,
            avatar_url: session.user.identities[0].identity_data.avatar_url,
            discord_id: session.user.identities[0].identity_data.provider_id,
          })
          .eq("id", session.user.id);
        if (error) {
          console.log(error);
        } else {
          console.log(session.user)
          updatedProfile.current = true;
        }
      }
    });
  }, []);

  // when user is populated
  useEffect(() => {
    // if user is not null, fetch the profile for the user
    if (user) {
      // console.log("Session", session);

      fetchProfile().then(async (fetchedProfile) => {
        console.log("Found profile", fetchedProfile);
        // if handle is null, get the handle from discord and insert it into the table as a default handle
        // if (fetchedProfile.handle === null) {
        //   //console.log("Handle from profile is null")
        //   // get formatted handle from discord data
        //   const handleString = (
        //     session.user.identities[0].identity_data.name.slice(0, -5) +
        //     session.user.identities[0].identity_data.name.slice(-4)
        //   ).toLowerCase();
        //   //console.log("Handle from Discord " + handleString)

        //   // insert handle from discord into table
        //   const { data, error } = await supabase
        //     .from("profiles")
        //     .update({ handle: handleString })
        //     .eq("id", user.id)
        //     .select("*");
        //   if (error) {
        //     console.log(error);
        //     return;
        //   }
        //   //console.log("Handle inserted", data)
        //   setProfile(data);
        //   openModal("editProfile", data)
        // } else {
          // just set the profile to the fetched profile if we don't need to do anything
          setProfile(fetchedProfile);
        // }

        //////////////////////////
        // PROVIDER TOKEN HANDLING
        //////////////////////////

        // check if the session provided a token -- this is always the most up to date token if it is received
        if (session.provider_token) { // if session provided token (happens on an actual log in from logged out)
          console.log("Found provider token from session")
          // save them to database right away to use them once the session stops providing them

          //save to database
          const { data, error } = await supabase
            .from("profiles")
            .update({ 
              provider_token: session.provider_token, 
              refresh_token: session.provider_refresh_token,
              provider_token_expires_at: new Date(session.expires_at*1000).toISOString().toLocaleString("en-US")
            })
            .eq("id", user.id)
            .select("*")
            .single()
          if (error) {
            console.log(error);
            return;
          }
          // console.log("Inserted token into database: ", data)
          setProviderToken(session.provider_token)

        // if the session does not have a token, we need to check if the database has a token
        } else if (fetchedProfile.provider_token) {
          // console.log("Found provider token from database", fetchedProfile.decrypted_provider_token, fetchedProfile.decrypted_refresh_token)

          // check if its expired
          const now = new Date().getTime();
          const expires = Date.parse(fetchedProfile.provider_token_expires_at);
          const diff = expires - now;

          if ((expires - now) < 0) {
            console.log("Provider token is expired");
            console.log("Refreshing with refresh token: ", fetchedProfile.decrypted_refresh_token)
            /////////////////////////////////////////////////////
            // refresh the token with refresh token from database
            /////////////////////////////////////////////////////
            const { data: refreshData, error: refreshError } = await supabase.functions.invoke("refresh-discord-token", {
              body: { token: fetchedProfile.decrypted_refresh_token },
            });
            if (refreshData.newAccess.error) {
              throw new Error(refreshData.newAccess.error + " while refreshing token.");
              return;
            }
            console.log("Refreshed provider token.")
            //console.log(refreshData)
            console.log("Refreshed token", refreshData.newAccess.access_token);
            console.log("New refresh token", refreshData.newAccess.refresh_token);
            console.log("New access token expires at", new Date(refreshData.newAccess.expires_in + now).toISOString().toLocaleString("en-US"));
            /////////////////////////////////////////
            // update the database with the new token
            /////////////////////////////////////////
            const { data: updateData, error: updateError } = await supabase
              .from("profiles")
              .update({
                provider_token: refreshData.newAccess.access_token,
                refresh_token: refreshData.newAccess.refresh_token,
                provider_token_expires_at: new Date(refreshData.newAccess.expires_in + now).toISOString().toLocaleString("en-US"),
              })
              .eq("id", user.id)
              .select("*")
              .single()
            if (updateError) {
              console.log(updateError);
              return;
            }
            console.log("Updated provider token in database: ", updateData);
            setProviderToken(updateData.decrypted_provider_token)

          } else { // if token is not expired
            console.log("Provider token is not expired");
            setProviderToken(fetchedProfile.decrypted_provider_token)
          }
        } else { // if token was not found in database or session
          console.log("No provider token found");
        }

      });

      fetchPosts();
      fetchUserVotedServerAddresses();
    }
  }, [user]);

  useEffect(() => {
    // console.log("Provider token setProviderToken = " + providerToken)
    if (providerToken) {
      // console.log("Provider token is set, fetching servers")
      getUserServers(providerToken).then((data) => {
        setMyServers(data.servers);
      })
    }
  }, [providerToken])
  

  // Modal Context Handling

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);

  function openModal(type, data) {
    console.log("openModal: Opening modal with type: " + type, data)
    setModalType(type);
    // TODO would be good to handle data to make sure it is suitable for the type, ie. editServer modal should receive a post object
    setModalData(data);
    setModalOpen(true);
  }

  return (
    
    <Router>
      <UserContext.Provider value={{user, session, providerToken, profile, setProfile, myServers, setUser, logIn, logOut, myPosts, myVotedServers}}>
        <ModalContext.Provider value={{openModal, modalOpen, setModalOpen, modalType, modalData,}}>
          <PayPalScriptProvider options={{
            vault: true,
            "client-id": "Af3iRrVsvy_Vqx2B0z0jBtuuhBd-5so9_2lW4wkjuzWmvAV_yjQVFIpi1WxrQ8bWiopVsmBgNjJ3sNlo",
            currency: "USD",
            intent: "subscription",
          }}>
            <Layout>
              <Routes>
                <Route path="/beta" element={<Beta />}/>
                <Route path="/" element={<Home />}/>
                <Route path="*" element={<NotFound />}/>
                <Route path="servers" element={<Search />}/>
                <Route path="assets" element={<Search />}/>
                <Route path="search/t=profiles" element={<Search />}/>
                <Route path="premium" element={<Premium />}/>
                <Route path="search" element={<Search />}/>
                {/* Dynamic routes from url parameters */}
                <Route path="server/:address" element={<Server />}/>
                <Route path="dev/:handle" element={<Dev />}/>
                <Route path="asset/:id" element={<Asset />}/>
              </Routes>
            </Layout>
          </PayPalScriptProvider>
        </ModalContext.Provider>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
