import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../App'
import { ModalContext } from "../../App";
import Button from '../../components/inputs/Button'
import ProCard from '../../components/cards/ProCard'
import BoostList from './BoostList';

const Premium = () => {
  const navigate = useNavigate()
  const maxBoosts = 2
  const { openModal } = useContext(ModalContext);
  const { user, profile } = useContext(UserContext);

  const [ availableBoosts, setAvailableBoosts ] = useState(0)

  useEffect(() => {
    if (profile) {
      setAvailableBoosts(maxBoosts - profile.boosts_used)
    }
    console.log(user)
  }, [profile])
  

  // TODO structure the conditional rendering better
  return (
    <>
    
      <div className="absolute -top-16 w-full bg-gradient-to-b from-blue-900/30 to-zinc-900/100 h-[800px]"/>

      {/* Top Section */}
      <div className="relative flex flex-col lg:flex-row justify-center items-center mt-12 gap-12 max-w-[1400px] mx-auto px-8">
        {/* Left Side */}
        <div>
          {/* Card */}
          <ProCard navigate={navigate} profile={profile} user={user}/>
          {/* Details */}
        </div>

        {/* Right Side */}
        <div className="w-full bg-zinc-900/50 rounded-lg border-2 border-zinc-700/50 p-4 lg:-ml-80 lg:pl-96">
          {user && profile ? (
            profile.pro_level > 0 ? (
              <>
                <h1 className="text-3xl font-bold text-zinc-300">Welcome, {profile.name}! </h1>
                <h2 className="text-zinc-400 mb-4">Pro level {profile.pro_level}</h2>
                <div className="bg-zinc-800 p-2 rounded mb-4">
                  Boosts remaining {availableBoosts}/{maxBoosts}
                </div>
                <Button 
                  onClick={() => {openModal('checkout')}}
                  label='Upgrade'
                />
              </>
            ) : (
              // {/* When not premium */}
              // {/* Perks of premium */}
              <div className="flex flex-col gap-4 max-w-3xl mx-auto">
                <div className="w-full bg-zinc-700 rounded-md p-4">
                  <h1 className="text-3xl font-bold text-zinc-200 mb-2">Server boosting!</h1>
                  <p className="text-zinc-400">Boosting a server makes it show higher up in searches, and gives it a chance to be featured on the home page!</p>
                </div>
                {/* <div className="w-full bg-zinc-800 rounded-md p-4">
                  <h1 className="text-3xl font-bold text-zinc-200 mb-2">Another feature!</h1>
                  <p className="text-zinc-400">Another great feature of FiveBoard Pro that can enhance your experience!</p>
                </div> */}
                <Button
                  onClick={() => {openModal('checkout')}}
                  label='Go pro!'
                />
              </div>
            )
          ) : null }
          
        </div>
      </div>

      {/* Bottom Section */}
      <BoostList user={user} profile={profile} maxBoosts={maxBoosts} availableBoosts={availableBoosts} setAvailableBoosts={setAvailableBoosts} />
      
    </>
  )
}
  export default Premium