import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../../components/Layout";
import { supabase } from "../../../lib/supabase";
import Tag from "../../../components/Tag";

const Post = () => {
  const { address } = useParams(); // Get the address from the URL
  const [postData, setPostData] = useState(null); // Store the post data

  // run once on page load
  useEffect(() => {
    getPostData();
  }, []);

  // get post data from supabase for the given address
  async function getPostData() {
    console.log("Trying to get post data for address: " + address);
    try {
      const { data, error } = await supabase
        .from("servers")
        .select("*")
        .eq("address", address);
      if (error) throw error;
      if (data != null) {
        setPostData(data);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  }

  // TODO skeleton and better conditional rendering
  if (postData === null) return "Loading..."; // If the post data is null, return "Loading..."

  return (
    <Layout>
      <div className="relative -mt-16 h-screen w-full bg-[url('https://i.imgur.com/Blnzu1h.png')] bg-cover bg-center">
        <div className="h-full w-full bg-gray-900/80 backdrop-blur-3xl"></div>

        <div className="absolute top-0 left-0 right-0 flex items-center justify-center">
          <div className="mx-12 mt-32 box-border w-full max-w-[1400px] rounded-xl border-2 border-white/20 bg-gray-900/50 p-12 md:mx-24">
            {/* Tags */}
            <div className="flex flex-wrap gap-2">
              {postData[0].tags.map((tag, index) => {
                return <Tag key={index}>{tag}</Tag>;
              })}
            </div>

            <hr className="my-4 opacity-20" />

            {/* Header */}
            <div className="flex flex-col items-stretch gap-4 sm:flex-row sm:gap-8">
              {/* Server logo */}
              <img
                className=" h-24 w-24 rounded-lg"
                src="https://i.imgur.com/Blnzu1h.png"
                alt=""
              />
              {/* Name and Category */}
              <div className="flex flex-grow flex-col items-start justify-around gap-2">
                <h1 className="text-5xl font-bold text-white">
                  {postData[0].name}
                </h1>

                <div
                  className="center relative inline-block select-none whitespace-nowraprounded-lg bg-gray-700 py-2 px-3.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white"
                >
                  {postData[0].category}
                </div>
              </div>
              {/* Button stack */}
              <div className="flex flex-grow flex-col items-stretch gap-2 sm:max-w-xs">
                <button className="flex-grow rounded-lg bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 py-1 px-4 font-bold lg:px-16">
                  Join Server
                </button>
                <button
                  className=" flex-grow rounded-lg bg-gray-700 py-1 px-4 font-bold
                                    lg:px-16"
                >
                  Vote ^
                </button>
              </div>
            </div>

            <hr className="my-4 opacity-20" />

            {/* Server description */}
            <div className="flex flex-col gap-4 md:flex-row">
              {/* left and right columns */}
              <div className="flex-grow basis-2/3">
                {postData[0].description}
              </div>
              <div className="flex-grow basis-1/3">Details</div>
            </div>

            {/* Assets */}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Post;
