import React, { useContext, useState, useEffect } from 'react'
import { supabase } from '../../lib/supabase'
import { UserContext, ModalContext } from '../../App'
import Spinner from '../Spinner'
import Tag from '../Tag'
// {/* // should it just go directly to external link? */}



const AssetCardContent = (props) => {
  const {asset, preview, profile} = props
  const {name, description} = asset
  const [ ownerName, setOwnerName ] = useState("")
  const [ ownerAvatar, setOwnerAvatar ]= useState("")

  async function fetchCreator(asset) {
    if (preview) {
      setOwnerAvatar(profile.avatar_url)
      return
    }

    const { data, error } = await supabase
      .from("assets")
      .select(`
        creator,
        profiles( avatar_url, name )
      `)
      .eq("id", asset.id)
      .single()
    if (error) console.log(error)
    if (data) {
      // console.log(data.profiles)
      // return data.profiles[0]
      setOwnerName(data.profiles.name)
      setOwnerAvatar(data.profiles.avatar_url)
    }
  }

  useEffect(() => {
    fetchCreator(asset)
  }, [asset])

  return (
    <div className="relative w-full h-full p-2 flex flex-col">
      <img alt="preview" src={asset.image} className="rounded-md aspect-video object-center object-cover flex items-center justify-center text-2xl text-zinc-600 font-bold"></img>

      <button onClick={() => {console.log("Asset clicked")}}>
        <div className="flex flex-col items-start justify-start mt-2 text-left px-2 mb-2 h-16 pointer-events-auto">
          <h1 className="font-bold text-zinc-300 line-clamp-1">{name}</h1>
          <p className="text-xs text-zinc-500 font-semibold line-clamp-2 my-1">{description}</p>
        </div>
      </button>

      {/* tags */}
      <div className="flex gap-2 flex-wrap px-2 mb-2">
        {asset.tags.length > 0 ? (
          asset.tags.map((tag, index) => {
            return <Tag key={index}>{tag}</Tag>
          })
        ):(
          <Tag>no tags</Tag>
        )}
      </div>
      {/* TODO add creator info */}
      <button className="absolute top-3 right-3 h-8 w-8 rounded-full hover:brightness-125 transition pointer-events-auto shadow-sm overflow-hidden shadow-black/40 bg-zinc-800">
        <img alt="" src={`${ownerAvatar}`}/>
      </button>

      
    </div>
    
  )
}

const AssetCardSkeleton = () => {
  return (
    <div className="relative w-full h-full p-2 flex flex-col">
      {/* <img alt="preview" src={asset.image} className="rounded-md aspect-video object-center object-cover flex items-center justify-center text-2xl text-zinc-600 font-bold"></img> */}
      <div className="w-full aspect-video bg-zinc-700 animate-pulse rounded-md mb-2"/>
      <div className="flex flex-col items-start justify-start text-left px-2 mt-2 h-16 gap-1">
        {/* <h1 className="font-bold text-zinc-300 line-clamp-1">{name}</h1> */}
        <div className="bg-zinc-700 animate-pulse h-4 w-1/2 rounded-md mb-1"/>
        {/* <p className="text-xs text-zinc-500 font-semibold line-clamp-2 my-1">{description}</p> */}
        <div className="bg-zinc-700/50 animate-pulse h-3 w-full rounded-sm"/>
        <div className={`bg-zinc-700/50 animate-pulse h-3 w-2/3 rounded-sm`}/>
      </div>
      {/* tags */}
      <div className="flex gap-2 flex-wrap px-2 mb-2">
        <div className="bg-zinc-700 animate-pulse h-6 w-16 rounded-md"/>
        <div className="bg-zinc-700 animate-pulse h-6 w-24 rounded-md"/>
        {/* {asset.tags.length > 0 ? (
          asset.tags.map((tag, index) => {
            return <Tag key={index}>{tag}</Tag>
          })
        ):(
          <Tag>no tags</Tag>
        )} */}
      </div>
      {/* TODO add creator info */}
    </div>
  )
}

const AssetCard = (props) => {
  const { openModal } = useContext(ModalContext)
  const {user, profile} = useContext(UserContext)
  const {skeleton, preview, asset} = props

  return (
    <div className="flex relative w-full flex-col items-center rounded-xl bg-zinc-800/90 backdrop-blur-2xl shadow-xl overflow-hidden hover:-translate-y-1 transition group h-fit">
      {asset?.creator === user?.id && 
        <div 
          className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center opacity-0 group-hover:opacity-100 bg-zinc-900/70 z-10 transition cursor-pointer pointer-events-auto"
          onClick={() => openModal("editAsset", asset)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 opacity-50">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
          </svg>
        </div>
      }
      { !skeleton && asset ? <AssetCardContent asset={asset} preview={preview} profile={profile}/> : <AssetCardSkeleton asset={asset}/>}
    </div>
  )
}

export default AssetCard