import { supabase } from '../supabase';
import { useState } from 'react';

let tags = []

const fetchTags = async () => {

  const { data, error } = await supabase
    .from('tags')
    .select('*')
    .order('uses', { ascending: false })
    if (error) {
      console.log("Error getting tags: ", error)
    } else {
      // console.log("Got tags: ", data)
      tags = [
        data.filter(tag => tag.type === 0),
        data.filter(tag => tag.type === 0 || tag.type === 1),
        data.filter(tag => tag.type === 0 || tag.type === 2),
        data.filter(tag => tag.type === 0 || tag.type === 3)
      ]
    }
  
}

fetchTags()

// types = all, servers, profiles
export const getTags = (type) => {
  // switch on types
  switch (type) {
    case "all":
      return tags[0];
    case "server":
      return tags[1];
    case "profile":
      return tags[2];
    case "asset":
      return tags[3];
    default:
      return tags[0];
  }
}