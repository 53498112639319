import React, { Fragment, useState, useEffect } from "react";
import { supabase } from "../../lib/supabase";
import { getTags } from "../../lib/services/tagsHandler";
import Button from "../inputs/Button";
import TextArea from "../inputs/TextArea";
import TagCloud from "../inputs/TagCloud";

const EditServer = ({ data, setOpen }) => {

  const allTags = getTags("server")
  console.log(allTags)
  const [descriptionValue, setDescription] = useState(data.description);
  const [selectedTags, setSelectedTags] = useState([]);
  const [unselectedTags, setUnselectedTags] = useState(getTags("server"));

  console.log(unselectedTags)

  const tagsOutput = selectedTags.map((tag) => {
    return `${tag.tag}`
  })

  // update post in database 
  // called when save button is clicked
  async function updatePost() {
    const {data : updateData, error} = await supabase
      .from("servers")
      .update({
        description: descriptionValue,
        tags: tagsOutput
      })
      .eq('id', data.id)
      .select('*')
      .single()
    if (error) console.log("Error updating post:", error)
    console.log("Updated post", updateData)
  }

  // set selected tags to the current tags
  useEffect(() => {
    if (data.tags) {
      // for each tag in data, find the tag it matches by the tag field in allTags and add it to selected tags
      data.tags.forEach((tag) => {
        allTags.forEach((tag2) => {
          if (tag === tag2.tag) {
            setSelectedTags((selectedTags) => [...selectedTags, tag2])
            // remove from unselected tags
            setUnselectedTags((unselectedTags) => unselectedTags.filter((tag3) => tag3.tag !== tag2.tag))
          }
        })
      })
    }
  }, [data.tags])

  return (
    <>
      <h1 className="text-xl font-bold">Editing {data.name}</h1>
      {/* description */}
      <TextArea name="description" value={descriptionValue} setValue={setDescription}></TextArea>
      {/* tags */} 
      <TagCloud count={selectedTags.length} max={data.boosted ? 6 : 3} showUses={true} initialTags={data.tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} unselectedTags={unselectedTags} setUnselectedTags={setUnselectedTags}></TagCloud>
      
      {/* Buttons */}
      <div className="mt-5 sm:mt-6 w-full">
        {/* delete */}
        <Button danger label="Delete post" onClick={
          () => {
            // TODO add delete button functionality
          }
        }/>
        {/* cancel & save */}
        <div className="mt-6 flex gap-2">
          <Button full secondary onClick={() => setOpen(false)} label="Cancel"/>
          <Button 
            full
            onClick={() => {
              updatePost()
              setOpen(false)
            }}
            label="Save changes"
          >
              Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditServer;
