import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../../lib/supabase";
import { UserContext } from "../../App";
import Button from "../../components/inputs/Button";
import Spinner from "../../components/Spinner";

export default function BoostList({user, profile, maxBoosts, availableBoosts, setAvailableBoosts}) {
  const { myPosts } = useContext(UserContext);
  const [processing, setProcessing] = useState(false)

  const [servers, setServers] = useState([])
  useEffect(() => {
    if (myPosts) {
      setServers(myPosts.map((post, index) => {
        return post
      }))
    }
  }, [myPosts])

  useEffect(() => {
    // if user is null then reset servers array
    if (!user) {
      setServers([])
    }
  }, [user])

  // server items
  const [items, setItems] = useState([])
  useEffect(() => {

    if (servers) {
      setItems(servers.map((post, index) => {
        return(
          <div key={index} className="flex justify-between w-full p-2 bg-zinc-800 rounded-md">
            <div className="flex gap-4 items-center">
              <img className="h-10 aspect-square rounded" src={post.icon_url + "?size=128"}/>
              <h1 className="text-lg font-semibold text-zinc-300">{post.name}</h1>
            </div>
            <Button
              label = {(post.boosted) ? "Un-boost" : "Boost"}
              special = {(!post.boosted) ? true : false}
              secondary = {(post.boosted) ? true : false}
              onClick={async () => {
                setProcessing(true)
                console.log("Click", post.name, post.boosted, profile.subscription_id)
                if (!post.boosted) {
                  console.log("Boosting", availableBoosts, profile.subscription_id)
                  //boost server
                  if (availableBoosts > 0) {
                    
                    const { data, error } = await supabase.from("servers").update({boosted: true}).eq('id', post.id)
                    if (error) {
                      console.log(error)
                    } else {
                      const newBoosts = availableBoosts - 1
                      setAvailableBoosts(newBoosts)
                      console.log(availableBoosts)
                      const {data, error} = await supabase.from('profiles').update({boosts_used: maxBoosts - newBoosts}).eq('id', profile.id)
                      if (error) {
                        console.log(error)
                      } else {
                        
                        // update this item in servers to reflect change
                        const newServers = [...servers]
                        newServers[index].boosted = true
                        setServers(newServers)
                      }
                    }
                  }
                } else {
                  //un-boost server
                  if (post.boosted) {
                    const { data, error } = await supabase.from("servers").update({boosted: false}).eq('id', post.id)
                    if (error) {
                      console.log(error)
                    } else {
                      const newBoosts = availableBoosts + 1
                      setAvailableBoosts(newBoosts)
                      console.log(availableBoosts)
                      const {data, error} = await supabase.from('profiles').update({boosts_used: maxBoosts - newBoosts}).eq('id', profile.id)
                      if (error) {
                        console.log(error)
                      } else {
                        // update this item in servers to reflect change
                        const newServers = [...servers]
                        newServers[index].boosted = false
                        setServers(newServers)
                      }
                    }
                  }
                }
                setProcessing(false)
              }}
            />
          </div>
        )
      }))
    }
  }, [servers])

  return(
    <div className={`relative px-8 max-w-5xl mx-auto mt-12 `}>
      {profile ? 
        profile.pro_level < 1 || !user ? 
          <div className="z-10 absolute flex flex-col justify-center items-center top-0 bottom-0 right-0 left-0">
            <p className="text-md text-zinc-400 font-semibold">Upgrade to</p>
            <span className="text-3xl font-bold bg-gradient-to-l from-indigo-500 via-blue-500 to-cyan-500 bg-clip-text">FiveBoard Pro</span>
            <p className="text-md text-zinc-400 font-semibold">to boost your servers</p>
          </div> 
        : 
          null 
      : 
        null
      }
      {processing ? 
        <div className="z-20 absolute flex flex-col justify-center items-center top-0 bottom-0 right-0 left-0 bg-zinc-900/50 mt-12 mx-8 rounded-md">
          <p className="text-md text-zinc-400 font-semibold"><Spinner/></p>
        </div> 
      :
        null
      }
      <div className={`${profile ? profile.pro_level < 1 || !user ? 'blur-md' : '' : null} flex flex-col justify-content-center items-center w-full `}>
        <h1 className="text-3xl font-bold text-zinc-300 mb-4">Manage server boosts!</h1>
        <div className="flex flex-col gap-4 border-2 border-zinc-700 p-4 rounded-lg w-full">
          {items}
        </div>
      </div>
      
      
    </div>
  )
}
  