import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../lib/supabase";
import { UserContext } from "../../App";
import Hero from "../../components/Hero";
import PostCard from "../../components/cards/PostCard";
import DevCard from "../../components/cards/DevCard";
import AssetCard from "../../components/cards/AssetCard";
import Button from "../../components/inputs/Button";

const Home = () => {
  const navigate = useNavigate();
  const { user, session, profile } = useContext(UserContext);
  
  const [posts, setPosts] = useState([]);
  const [devs, setDevs] = useState([]);
  const [assets, setAssets] = useState([]);

  const fetchFeaturedPosts = async () => {

    let results = []

    // get 4 random boosted posts
    const { data, error } = await supabase.rpc('get_random_boosted_servers', {count: 3})
    if (error) {
      console.error(error)
    } else {
      // console.log(data)
    }
    // sort the results by votes (highest first)
    data.sort((a, b) => b.votes - a.votes)
    // add them to the results
    results = [...results, ...data]
    setPosts(results)
  };

  // fetch featured devs
  const fetchFeaturedDevs = async () => {
    let results = []
    // get 3 random devs with pro
    const { data, error } = await supabase.rpc('get_random_pro_devs', {count: 3})
    if (error) {
      console.error(error)
    } else {
      console.log(data)
      // sort the results by votes (highest first)
      data.sort((a, b) => b.votes - a.votes)
      // add them to the results
      results = [...results, ...data]
      setDevs(results)
    }
  };

    // fetch featured Assets
    const fetchFeaturedAssets = async () => {
      let results = []
      // get 3 random Assets with pro
      const { data, error } = await supabase
        .from('assets')
        .select('*')
        .order('created_at', { ascending: false })
      if (error) {
        console.error(error)
      } else {
        console.log(data)
        // sort the results by votes (highest first)
        data.sort((a, b) => b.votes - a.votes)
        // add them to the results
        results = [...results, ...data]
        setAssets(results)
      }
    };

  useEffect(() => {
    fetchFeaturedPosts();
    fetchFeaturedDevs();
    fetchFeaturedAssets();
  }, []);

  useEffect(() => {
    if (!user) {
      navigate("/beta")
    }
  }, [user])

  const cards = posts.map((post, index) => {
    return <PostCard key={index} post={post} />;
  })

  const devCards = devs.map((dev, index) => {
    return <DevCard key={index} dev={dev} />;
  })

  const assetCards = assets.map((asset, index) => {
    return <AssetCard key={index} asset={asset} />;
  })

  return (
    <>
      <Hero/>

      <div className="max-w-[1400px] px-8 mx-auto">
        
        {/* assets */}
        <div className="flex flex-col sm:flex-row mt-4 gap-2 items-center text-zinc-400">
        <h1 className="text-2xl font-bold text-gray-200 text-center sm:text-left">Featured assets</h1>
          <button 
            className="hover:scale-90 transition"
            secondary 
            onClick={() => {
              fetchFeaturedPosts()
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </button>
          <a className="sm:ml-auto" href="/search/?t=servers&q=">All assets</a>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-6">
            {assetCards.length > 0 ? (
              assetCards
            ):(
              <>
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
              </>
            )}
        </div>

        {/* servers */}
        <div className="flex flex-col sm:flex-row mt-4 gap-2 items-center text-zinc-400">
          <h1 className="text-2xl font-bold text-gray-200 text-center sm:text-left">Featured servers</h1>
          <button 
            className="hover:scale-90 transition"
            secondary 
            onClick={() => {
              fetchFeaturedPosts()
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
          </button>
          <a className="sm:ml-auto" href="/search/?t=servers&q=">All servers</a>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
          {posts.length > 0 ? (
            <>
              {cards}
            </>
          ) : (
            <>
              <PostCard skeleton />
              <PostCard skeleton />
              <PostCard skeleton />
              <PostCard skeleton />
            </>
          )}
        </div>

        {/* pro ad */}
        <div className="mt-6 bg-zinc-800 rounded-lg w-full h-48">
        </div>

        {/* <h1>Featured Devs</h1> */}
        <div className="mt-6 bg-gradient-to-b from-zinc-800 to-zinc-900 rounded-lg p-4">
        <div className="flex flex-col sm:flex-row">
            <div className="flex flex-col text-center sm:text-left">
              <h1 className="text-3xl font-bold text-gray-200">Featured Devs</h1>
              <p className="text-gray-400">Random pro users</p>
            </div>
            <div className="flex gap-2 mt-2 justify-center sm:ml-auto items-start">
              <Button 
                label={
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                  </svg>
                }
                secondary 
                onClick={() => {
                  fetchFeaturedDevs();
                }}
              />
              <Button label='Browse Devs' secondary onClick={() => { navigate('/search/?t=profiles') }}/>
            </div>
          </div>
          
          <div className="flex justify-center gap-4 flex-wrap mt-6">
            {devCards}
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
