import React from 'react'
import DevCard from '../../components/cards/DevCard'
import Spinner from '../../components/Spinner'

const DevResults = (props) => {
  const { loading, results } = props
  return (
    <div className="group mx-auto flex flex-col sm:flex-row flex-wrap justify-center max-w-[1400px] gap-4">
          {loading && results.length === 0 ? (
            <div className="flex justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              {results.map((result, index) => {
                return (
                  <div
                    key={index}
                    className="transition duration-200 hover:!brightness-100 group-hover:brightness-[.85]"
                  >
                    <DevCard dev={result} />
                  </div>
                );
              })}
            </>
          )}
        </div>
  )
}

export default DevResults