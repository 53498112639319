
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export function SearchBox({
  searchType,
  searchQuery,
  handleInputChange,
  handleSearchEvent
}) {
  return (
    <div className="col-span-3">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-8">
          <MagnifyingGlassIcon className="h-5 w-5 text-zinc-400" aria-hidden="true" />
        </div>
        <input className="block h-12 w-full rounded-r-lg border-0 bg-zinc-700/40 py-1.5 pl-16 pr-3 text-zinc-300 placeholder:text-zinc-500 focus:bg-zinc-800/80 focus:text-white focus:ring-0 sm:h-16 sm:text-2xl sm:leading-6" id="search" type="search" name="search" placeholder={`Search ${searchType ? `for ${searchType.name.toLowerCase()}` : ''}`} value={searchQuery} onChange={handleInputChange} onKeyPress={handleSearchEvent} />
      </div>
    </div>
  );
}
  