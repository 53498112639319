import React, { useEffect } from "react";
import Tilt from "react-parallax-tilt";
import Tag from "../../components/Tag";

export default function ProCard({ navigate, profile, user }) {

  return (
    // /* https://github.com/mkosir/react-parallax-tilt */
    <Tilt
      tiltReverse
      transitionSpeed={1800}
      trackOnWindow
      tiltMaxAngleX={7}
      tiltMaxAngleY={7}
      tiltAngleXInitial={-7}
      tiltAngleYInitial={7}
      glareEnable={true}
      glareMaxOpacity={0.2}
      glareColor="#94a3b8"
      glarePosition="top"
      glareBorderRadius="24px"
    >
      {/* TODO change border based on tier */}
      <button
        className="relative flex aspect-video w-[480px] flex-col items-center  justify-center gap-3 overflow-hidden rounded-3xl bg-zinc-900 transition-all inner-border-2 inner-border-blue-500 hover:brightness-110 sm:w-[600px]"
        onClick={() => {
          if (profile.pro_level > 0) {
            navigate(`/dev/${profile.handle}`);
          } else {
            // buy pro
          }
        }}
      >
        {(user && profile ? profile.pro_level > 0 : false) ? ( // {/* Content if premium */}
          <>
            <img
              className="absolute bottom-1/2 left-1/2 right-1/2 top-1/2 z-0 w-full -translate-x-1/2 -translate-y-1/2 opacity-50 blur-3xl gradient-mask-tl-0"
              src={profile.avatar_url.replace(/\.[^/.]+$/, ".jpg") + "?size=24"}
            />
            <div className="absolute bottom-0 left-0 right-0 top-24 rounded-3xl bg-repeat-space mix-blend-overlay inner-border-2 gradient-mask-t-0 heropattern-tinycheckers-white/50"></div>
            <div className="z-10 flex flex-col items-center justify-center gap-2 sm:gap-4">
              <h2 className="font-semibold text-zinc-400">
                FiveBoard Pro member since {new Date(profile.pro_date).toLocaleDateString('en-US', {month: 'long', year: 'numeric', day: 'numeric'})}
              </h2>
              <img
                className="h-32 w-32 rounded-full"
                src={profile.avatar_url + "?size=128"}
                alt=""
              />
              <h1 className="text-4xl font-bold text-white">{profile.name}</h1>
              <Tag large solid>
                Pro
              </Tag>
            </div>
          </> // {/* Content if not premium */}
        ) : (
          <>
            <div className="flex h-32 w-32 items-center justify-center rounded-full bg-zinc-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="h-16 w-16"
              >
                <path
                  fillRule="evenodd"
                  d="M15.22 6.268a.75.75 0 01.968-.432l5.942 2.28a.75.75 0 01.431.97l-2.28 5.941a.75.75 0 11-1.4-.537l1.63-4.251-1.086.483a11.2 11.2 0 00-5.45 5.174.75.75 0 01-1.199.19L9 12.31l-6.22 6.22a.75.75 0 11-1.06-1.06l6.75-6.75a.75.75 0 011.06 0l3.606 3.605a12.694 12.694 0 015.68-4.973l1.086-.484-4.251-1.631a.75.75 0 01-.432-.97z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <h1 className="text-4xl font-bold text-white">
              Get FiveBoard Pro!
            </h1>
            <h2 className="text-xl font-medium text-zinc-300">
              Unlock server boosting to get more views and joins.
            </h2>
          </>
        )}
      </button>
      {/* Glow */}
      {/* TODO change color based on tier */}
      {user ? (
        profile.pro_level > 0 ? (
          <div className="absolute -inset-2 -z-10 rounded-3xl bg-blue-500 opacity-50 blur-2xl" />
        ) : null
      ) : null}
    </Tilt>
  );
}
