import React, { useContext, useState } from 'react'
import { supabase } from '../../lib/supabase'
import { UserContext } from '../../App'
import { getTags } from '../../lib/services/tagsHandler'

import AssetCard from '../cards/AssetCard'
import TextInput from '../inputs/TextInput'
import TextArea from '../inputs/TextArea'
import Button from '../inputs/Button'
import TagCloud from '../inputs/TagCloud'

const NewAsset = (props) => {
  const { setOpen } = props
  const { user, profile } = useContext(UserContext)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [link, setLink] = useState('')
  const [image, setImage] = useState('')
  const [selectedTags, setSelectedTags] = useState([])
  const [unselectedTags, setUnselectedTags] = useState(getTags("asset"))

  // create asset in database
  async function createAsset(){
    const { error } = await supabase
      .from('assets')
      .insert([{
          owner: user.id,
          name: name,
          description: description,
          link: link,
          image: image,
          tags: selectedTags.map((tag) => {
            return `${tag.tag}`
          }
        )
      }])
    if (error) {
      console.log("Error creating asset:", error) 
      return
    }
    console.log("Created asset!")
  }

  return (
    <div className="relative">
      <div className="absolute bg-gradient-to-b from-zinc-900/80 to-zinc-800 -top-6 -right-6 -left-6 bottom-0 -z-10"/>
      <AssetCard preview asset={{name: name, description: description, link: link, image: image, tags: selectedTags}} />
      <hr className="opacity-20 mt-4 mb-2"/>

      {/* // Name */}
      <TextInput type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Ex. LSPD 2021 FPIU" />
      {/* // Description */}
      <TextArea name="description" value={description} setValue={setDescription} placeholder="Ex. This is a 2021 Ford Police Interceptor Utility for the Los Santos Police Department. ULC compatible!" />
      {/* // Type? */}
      
      {/* // link */}
      <TextInput type="text" name="link" value={link} onChange={(e) => setLink(e.target.value)} placeholder="Ex. https://www.lcpdfr.com/downloads/gta5mods/vehiclemodels/32654-els-2021-ford-police-interceptor-utility-lspd/" />
      {/* // image */}
      <TextInput type="text" name="image" value={image} onChange={(e) => setImage(e.target.value)} placeholder="Ex. https://i.imgur.com/QBCOaKa.jpg" />
      {/* // tags */}
      <TagCloud selectedTags={selectedTags} setSelectedTags={setSelectedTags} unselectedTags={unselectedTags} setUnselectedTags={setUnselectedTags} />
      
      
      {/* cancel & save */}
      <div className="mt-6 flex gap-2">
        <Button full secondary onClick={() => setOpen(false)} label="Cancel"/>
        <Button 
          full
          onClick={() => {
            createAsset()
            setOpen(false)
          }}
          label="Create"
        >
            Save
        </Button>
      </div>
    </div>
  )
}

export default NewAsset