import React, { useState, createContext, useContext } from 'react'
import { supabase } from '../supabase';

export const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [session, setSession] = useState(null)
  const [profile, setProfile] = useState(null)

  // USER CONTEXT HANDLING
  //https://dev.to/supabase/what-is-oauth-setting-up-github-oauth-with-supabase-44le
  async function userStatus() {
    supabase.auth.getSession().then((result) => {
      // console.log(result.data.session)
      const session = result.data.session;
      if (session) {
        setUser(result.data.session.user);
      }
      setSession(result.data.session);
    });
  }
  console.log("Origin:", window.location.origin)
  async function logIn() {
    
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "discord",
      options: {
        scopes: "identify guilds guilds.join",
      },
      redirectTo: window.location.origin,
    });
    if (error) {
      console.log(error);
    }
  }    

  async function logOut() {
    const { error } = await supabase.auth.signOut();
    setUser(null);
    setSession(null);
  }

  async function fetchProfile() {
    let result
    //console.log("Fetching profile")
    const { data, error } = await supabase
      .from("decrypted_profiles")
      .select("*")
      .eq("id", user.id)
      .single();
    if (error) {
      console.log(error);
    } else {
      // let result = data
      // //console.log(data);

      // // get the decrypted token
      // const {data : decryptedData, error} = await supabase
      //   .from("decrypted_profiles")
      //   .select("*")
      //   .eq("id", user.id)
      //   .single()
      
      // if (error) {
      //   console.log(error);
      // } else {
      //   console.log("Decrypted token", decryptedData.decrypted_refresh_token)
      // }
      
      // console.log(data)
      // result.decrypted_refresh_token = decryptedData.decrypted_refresh_token
      // result.decrypted_provider_token = decryptedData.decrypted_provider_token

    }
    
    setProfile(data)
    return data;
  }


  const value = {
    // states
    user,
    setUser,
    session,
    setSession,
    profile, 
    setProfile,
    // functions
    userStatus,
    logIn,
    logOut,
    fetchProfile
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}