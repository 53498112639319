import React, { useState, useContext } from 'react'
import { supabase } from '../../lib/supabase'
import { ModalContext } from '../../App'
import { getTags } from '../../lib/services/tagsHandler'
import TagCoud from '../inputs/TagCloud'
import TextInput from '../inputs/TextInput'
import TextArea from '../inputs/TextArea'
import Button from '../inputs/Button'
import AssetCard from '../cards/AssetCard'

const Form = ({asset, setOpen}) => {
  // const { setOpen } = useContext(ModalContext)

  const [saving, setSaving] = useState(false)
  const [selectedTags, setSelectedTags] = useState([])
  const [unselectedTags, setUnselectedTags] = useState(getTags("asset"));

  const [name, setName] = useState(asset.name)
  const [description, setDescription] = useState(asset.description)
  const [link, setLink] = useState(asset.link)
  const [image, setImage] = useState(asset.image)


  const saveChanges = async () => {
    setSaving(true)
    // update db
    const { data : saveData, error } = await supabase
      .from("assets")
      .update({
        name: name,
        description: description,
      })
      .eq("id", asset.id);
    if (error) {
      console.log(error);
    }
    setSaving(false)
  };

  return (
    <>
      <AssetCard preview asset={{name: name, description: description, link: link, image: image, tags: selectedTags}} />
      <div className="flex flex-col gap-2">
        <TextInput 
          label="Name"
          name="name"
          value={name}
          onChange={(e) => {setName(e.target.value)}}
        />
        <TextArea
          label="Description"
          name="description"
          value={description}
          setValue={setDescription}
        />
        <TextInput
          label="Link"
          name="link"
          value={link}
          onChange={(e) => {setLink(e.target.value)}}
        />
        <TextInput
          label="Image"
          name="image"
          value={image}
          onChange={(e) => {setImage(e.target.value)}}
        />
        <TagCoud
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          unselectedTags={unselectedTags}
          setUnselectedTags={setUnselectedTags}
        />
      </div>

      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
      <Button secondary onClick={() => setOpen(false)} label="Cancel">Cancel</Button>
      <Button 
        // disabled={ !handleAvailable || !handleValid } 
        isLoading={
          // handleDirty || handleProcessing || 
          saving
        } 
        onClick={() => {
          saveChanges().then(() => {
            setOpen(false)
          })
        }} 
        label="Save">Save</Button>
      </div>
    </>
  )

}

const Skeleton = () => {
  return (
    <div className="w-full h-full bg-red-500">
    </div>
  )
}

const EditAsset = (props) => {
  const { data: asset, setOpen } = props

  if (asset.name) {
    return <Form asset={asset} setOpen={setOpen} />
  } else {
    return <Skeleton />
  }
}

export default EditAsset