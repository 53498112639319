import React, { useContext, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { supabase } from '../../../lib/supabase'
import { UserContext, ModalContext } from '../../../App'

import Button from '../../../components/inputs/Button'
import PostCard from '../../../components/cards/PostCard'
import AssetCard from '../../../components/cards/AssetCard'

import ProfileSection from './ProfileSectionNew';

const Dev = () => {
  // context
  const { user, profile } = useContext(UserContext)
  const { openModal } = useContext(ModalContext)
  // handle from URL
  const { handle } = useParams()

  const [dev, setDev] = useState(null)
  const [servers, setServers] = useState([])
  const [assets, setAssets] = useState([])

  useEffect(() => {
    if (handle === (profile ? profile.handle : null)) {
      console.log("This is my profile.")
      if (profile) setDev(profile)
    }
    fetchContent()
  }, [profile, handle])

  const fetchContent = async () => {
    // fetch dev
    const { data, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('handle', handle)
      .single()
    if (error) {
      console.log('error', error)
    } else {
      setDev(data)
      // fetch dev servers
      const { data: servers, error: serversError } = await supabase
        .from('servers')
        .select('*')
        .eq('owner', data.id)
        .limit(2)
      if (serversError) {
        console.log('serversError', serversError)
      } else {
        setServers(servers)
        // fetch dev assets
        const { data: assets, error: assetsError } = await supabase
          .from('assets')
          .select('*')
          .eq('owner', data.id)
        if (assetsError) {
          console.log('assetsError', assetsError)
        } else {
          setAssets(assets)
        }
      }
    }
  }

  const serverCards = servers.map((server) => {
    return (
      <PostCard key={server.id} full post={server} />
    )
  })
  
  const assetCards = assets.map((asset) => {
    return (
      <AssetCard key={asset.id} asset={asset} />
    )
  })

  return (
    <div className="w-full -mt-16 pt-16">
      <div className="w-full max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="mb-8">
          <ProfileSection dev={dev}/>
        </div>
        
        {/* Servers */}
        <div className="w-full max-w-[1400px] mx-auto mb-8">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Servers</h2>
            {profile?.handle === dev?.handle && <Button label="Add server" onClick={() => openModal('newServer')} />}
          </div>
          <div className="flex flex-col flex-wrap justify-center gap-4">
            {serverCards.length > 0 ? (serverCards) : (
              <div>
                <PostCard full skeleton />
                {profile?.pro_level > 0 && <PostCard full skeleton />}
              </div>
            )}
          </div>
        </div>

        {/* Assets */}
        <div className="w-full max-w-[1400px] mx-auto">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Assets</h2>
            {profile?.handle === dev?.handle && <Button label="Add asset" onClick={() => openModal('newAsset')} />}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {assetCards.length > 0 ? (
              assetCards
            ):(
              <>
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
              </>
            )}
            <>
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
                <AssetCard skeleton />
              </>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dev