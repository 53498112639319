import React, { useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Button from "./inputs/Button";
//import { UserContext } from "../App"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Header = ({user, profile, setUser, logIn, logOut, hideSearch}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()

  const navItems=[
    {'key': 1, 'name': 'Servers', 'link': "search/?t=servers"},
    {'key': 2, 'name': 'Developers', 'link': "search/?t=profiles"},
    {'key': 3, 'name': 'Assets', 'link': "search/?t=assets"},
    {'key': 4, 'name': 'Premium', 'link': "premium"},
  ]

  function handleSearch(e) {
    let search;
    if (e.target.value) {
      search = {
        t: "servers",
        q: e.target.value
      }
    } else {
      search = {
        t: "servers",
        q: ""
      }
    }
    setSearchParams(search, {replace: true})
  }

  function handleEvent(e) {
    if (e.key === "Enter") {
      console.log("Enter pressed", searchParams);
      if (searchParams.get("q")){
        navigate(`/search/?${searchParams}`);
      } else {
        navigate(`/search/?t=servers&q=`);
      }
    }
  }

  // map nav items from props
  const expandedNavItems = navItems.map((item) =>
    <Link
      key={item.key}
      to={`/${item.link}`}
      className={`rounded-md  px-3 py-2 text-sm font-medium ${location.pathname === `/${item.link}` ? "bg-zinc-800 text-white" : "text-zinc-300 hover:bg-zinc-700/50 hover:text-white"}`}
    >
      {item.name}
    </Link>
  );

  const collapsedNavItems = navItems.map((item) =>
    <Disclosure.Button
      key={item.key}
      as={Link}
      to={`/${item.link}`}
      className={`block rounded-md px-3 py-2 text-base font-medium ${location.pathname === `/${item.link}` ? "bg-zinc-900  text-white" : "text-zinc-300 hover:bg-zinc-700/50 hover:text-white"}`}
    >
      {item.name}
    </Disclosure.Button>
  );

  // background color on scroll
  const [bgClassName, setBgClassName] = useState("bg-transparent");
  const handleScroll = (e) => {
    if (window.pageYOffset > 0) {
      // TODO for some reason the bottom border flashes white on first scroll
      setBgClassName("bg-zinc-900/[85%] backdrop-blur-2xl border-b border-zinc-800");
    } else {
      setBgClassName("bg-transparent border-b border-zinc-800/0");
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Disclosure
      as="nav"
      className={`sticky top-0 z-10 transition ${bgClassName}`}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-[1400px] px-2 sm:px-4 lg:px-8">
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex items-center px-2 lg:px-0">
                <Link to="/" className="flex-shrink-0">
                  <img
                    className="block h-8 w-auto lg:hidden"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                  <img
                    className="hidden h-8 w-auto lg:block"
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                    alt="Your Company"
                  />
                </Link>

                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">{expandedNavItems}</div>
                </div>
              </div>

              {/* Search bar */}
              {!hideSearch ? (
                <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
                  <div className="w-full max-w-lg lg:max-w-xs">
                    <label htmlFor="search" className="sr-only">
                      Search
                    </label>
                    <div className="relative">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <MagnifyingGlassIcon
                          className="h-5 w-5 text-zinc-400"
                          aria-hidden="true"
                        />
                      </div>
                      <input
                        id="search"
                        name="search"
                        className="block w-full rounded-md border-0 bg-zinc-700/50 py-1.5 pl-10 pr-3 text-zinc-300 placeholder:text-zinc-400 focus:bg-zinc-800/80 focus:text-white focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Search"
                        type="search"
                        onChange={handleSearch}
                        onKeyPress={handleEvent}
                      />
                    </div>
                  </div>
                </div>
              ) : (<></>)}
              

              <div className="flex lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-zinc-400 hover:bg-zinc-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              {/* Desktop logged in state buttons */}
              {user ? (
                <div className="hidden lg:ml-4 lg:block">
                  <div className="flex items-center">
                    {/* Profile icon + button*/}
                    <Menu as="div" className="relative ml-4 flex-shrink-0">
                      <div>
                        <Menu.Button className="flex rounded-full bg-zinc-800 text-sm text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-zinc-800">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full"
                            src={user.identities[0].identity_data.avatar_url + "?size=64"}
                            alt=""
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-zinc-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {/* <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/dashboard"
                                className={classNames(
                                  active ? "bg-zinc-700" : "",
                                  "block px-4 py-2 text-sm text-zinc-300"
                                )}
                              >
                                Dashboard
                              </Link>
                            )}
                          </Menu.Item> */}
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to={profile ? `/dev/${profile.handle}` : '/dev/'}
                                className={classNames(
                                  active ? "bg-zinc-700" : "",
                                  "block px-4 py-2 text-sm text-zinc-300"
                                )}
                              >
                                My profile
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                onClick={() => {
                                  logOut();
                                  navigate("/")
                                }}
                                className={classNames(
                                  active ? "bg-zinc-700" : "",
                                  "block w-full px-4 py-2 text-sm text-zinc-300"
                                )}
                              >
                                Sign out
                              </a>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              ) : (
                <div className="hidden lg:ml-4 lg:block">
                  <div className="flex items-center">
                    <Button 
                      full
                      onClick={() => {logIn()}}
                      label={
                        <div className="flex justify-center gap-2 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord mt-1" viewBox="0 0 16 16">
                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                          </svg>
                          Log in
                        </div>
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Mobile menu, show/hide based on menu open state. */}
          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">{collapsedNavItems}</div>

            {/* mobile buttons affected by login state */}
            {user ? ( // mobile logged in buttons
              <div className="border-t border-zinc-700 pt-4 pb-3">
                <div className="flex items-center px-5">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={user.identities[0].identity_data.avatar_url}
                      alt=""
                    />
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-zinc-800 p-1 text-zinc-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-zinc-800"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1 px-2">
                  {/* <Disclosure.Button
                    as={Link}
                    to="/dashboard"
                    className="block rounded-md px-3 py-2 text-base font-medium text-zinc-400 hover:bg-zinc-700 hover:text-white"
                  >
                    Dashboard
                  </Disclosure.Button> */}
                  <Disclosure.Button
                    as={Link}
                    to={profile ? `/dev/${profile.handle}` : '/dev/'}
                    className="block rounded-md px-3 py-2 text-base font-medium text-zinc-400 hover:bg-zinc-700 hover:text-white"
                  >
                    My Profile
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="#"
                    onClick={() => {
                      logOut();
                    }}
                    className="block rounded-md px-3 py-2 text-base font-medium text-zinc-400 hover:bg-zinc-700 hover:text-white"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            ) : (
              // mobile logged out
                <div className="mt-3 space-y-1 px-2">
                  <Disclosure.Button
                    as="a"
                    href="#"
                    onClick={() => {
                      logIn();
                    }}
                  >
                    <Button 
                      full
                      onClick={() => {logIn()}}
                      label={
                        <div className="flex justify-center gap-2 items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord mt-1" viewBox="0 0 16 16">
                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                          </svg>
                          Log in
                        </div>
                      }
                    />
                  </Disclosure.Button>
                </div>
            )}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Header;
