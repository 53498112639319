import React from 'react'

const TextArea = ({name, defaultValue, value, setValue, rows}) => {
  
  return (
    <div>
      <label htmlFor="text" className="block text-sm font-medium leading-6 text-zinc-400">
        {name.charAt(0).toUpperCase() + name.slice(1)}
      </label>
      <div className="mt-2">
        <textarea
          rows={rows}
          name={name}
          id={name}
          defaultValue={defaultValue}
          value={value}
          className="block w-full bg-zinc-700 rounded-md border-0 py-1.5 text-zinc-50 shadow-sm ring-1 ring-inset ring-zinc-800 placeholder:text-zinc-600 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(e) => setValue(e.target.value)}
        >
        </textarea>
      </div>
    </div>
  )
}

export default TextArea