import React from 'react'
import Tag from '../Tag'
import Button from '../inputs/Button'

const DevCard = ({skeleton, dev}) => {
  
  if (!skeleton && dev) {
    return (
      <div
        className={`rounded-2xl p-[3px] mt-8 ${
          dev.pro_level > 0
            ? "bg-gradient-to-t from-blue-900 to-blue-500"
            : "bg-zinc-600"
        }`}
      >
        <div className="flex w-80 min-h-full flex-col items-center rounded-xl bg-zinc-800/90 p-4 backdrop-blur-2xl shadow-xl hover:brightness-110 overflow-visible aspect-square">
          {/* Icon */}
          <img alt="Profile Icon" className="-mt-12 w-full max-w-[128px] rounded-full border-4 border-zinc-600" src={dev ? dev.avatar_url + "?size=128" : "https://i.imgur.com/kIfXEFa.png"} />
          {/* Name */}
          <h1 className="text-zinc-300 text-3xl font-bold mt-4">{dev ? dev.name : ""}</h1>
          {/* Handle */}
          <h2 className="text-zinc-400 ">
            @{dev ? dev.handle : ""}
          </h2>
          {/* Tags */}
          <div className="flex flex-wrap gap-2 mt-4 justify-center px-8">
            {dev.tags.map((tag, index) => {
              return <Tag key={index}>{tag}</Tag>
            })}
          </div>
          {/* Bio */}
          <p className="text-zinc-400 line-clamp-2 text-center mt-4">
            {dev ? dev.bio : ""}
          </p>
          {/* Buttons */}
          <div className="flex w-full gap-2 mt-8">
            <Button full secondary label="View" onClick={() => {}}></Button>
            <Button full onClick={() => {}} 
              label={
                <div className="flex justify-center items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mt-1">
                    <path d="M1 8.25a1.25 1.25 0 112.5 0v7.5a1.25 1.25 0 11-2.5 0v-7.5zM11 3V1.7c0-.268.14-.526.395-.607A2 2 0 0114 3c0 .995-.182 1.948-.514 2.826-.204.54.166 1.174.744 1.174h2.52c1.243 0 2.261 1.01 2.146 2.247a23.864 23.864 0 01-1.341 5.974C17.153 16.323 16.072 17 14.9 17h-3.192a3 3 0 01-1.341-.317l-2.734-1.366A3 3 0 006.292 15H5V8h.963c.685 0 1.258-.483 1.612-1.068a4.011 4.011 0 012.166-1.73c.432-.143.853-.386 1.011-.814.16-.432.248-.9.248-1.388z" />
                  </svg>
                  523
                </div>
              }
            ></Button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="placeholder:rounded-2xl p-[3px] bg-zinc-600">
      </div>
    )
  }
}

export default DevCard