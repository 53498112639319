import React, { Fragment, useRef, useState, useContext } from "react";
import { supabase } from "../../lib/supabase";
import { UserContext } from "../../App";
import { Listbox, Dialog, Transition } from "@headlessui/react";
import Button from "../inputs/Button";

const NewServer = ({openModal, setOpen}) => {

  const { user, profile, myServers } = useContext(UserContext);
  const servers = myServers
  const [selected, setSelected] = useState(servers[0]);
  const [error, setError] = useState('');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="overflow-visible">
      {/* choose server */}
      {/* maybe just choose server then go right to a edit server modal? */}


      <Listbox value={selected} onChange={setSelected}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-400">Choose a server to add!</Listbox.Label>
            <div className="relative mt-2">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-gray-700 py-1.5 pl-3 pr-10 text-left text-gray-200 font-semibold shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <img src={`https://cdn.discordapp.com/icons/${selected.id}/${selected.icon}.webp?size=64`} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span className="ml-3 block truncate">{selected.name}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  {/* <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-gray-700 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {servers.map((server) => (
                    <Listbox.Option
                      key={server.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-indigo-600 text-white' : 'text-gray-300',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={server}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            {server.icon ? 
                              <img src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.webp?size=64`} alt="" className="h-5 w-5 flex-shrink-0 rounded-full shadow-sm " /> 
                              : 
                              <div className="h-5 w-5 flex-shrink-0 rounded-full shadow-sm bg-gray-600 text-center font-bold leading-0 text-xs">{server.name.split(' ').map(word => word.charAt(0)).join('')}</div>
                            }
                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                            >
                              {server.name}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>



      {/* Error */}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
        {/* Buttons */}
        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
          <Button full secondary onClick={() => setOpen(false)} label='Cancel'>Cancel</Button>
          <Button full onClick={async () => {
            const {data, error} = await supabase
            .from("servers")
            .insert([
              { name: selected.name, 
                address: selected.id, 
                server_id: selected.id, 
                owner: user.id, 
                members: selected.approximate_member_count,
                icon_url: selected.icon ? `https://cdn.discordapp.com/icons/${selected.id}/${selected.icon}` : null, },
            ])
            .select()
            .single()
            
            // TODO would be better to handle this before allowing the user to click the button
            if (error) {
              console.log(error)
              if (error.code === '23505') {
                setError('You\'ve already added this server!')
              }
            } else {
              console.log(data)
              openModal('editServer', data)
            }
          }
            
          }
            label='Create'
          >
            Create
          </Button>
        </div>
    </div>
  )
}

export default NewServer