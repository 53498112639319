
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getTags } from "../../lib/services/tagsHandler";
import { SearchBox } from './SearchBox';
import ServersResults from "./ServersResults";
import DevResults from "./DevResults";
import AssetsResults from "./AssetsResults";
import TagCloud from "../../components/inputs/TagCloud";
import Button from "../../components/inputs/Button";
import TypeSelector from "./TypeSelector";
import Spinner from "../../components/Spinner";

import useSearch from "../../lib/hooks/useSearch";

const searchTypes = [
  { id: 1, name: 'Servers', value: 'servers'},
  { id: 2, name: 'Developers', value: 'profiles'},
  { id: 3, name: 'Assets', value: 'assets'},
]

// TODO does not trigger a search when search type in searchparams is changed by clicking buttons on header
const Search = () => {
  // const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadIndex, setLoadIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [executeSearch, loadingResults, noMoreResults] = useSearch(results, setResults)
 
  const [searchType, setSearchType] = useState(searchTypes[0]);
  const [searchQuery, setSearchQuery] = useState(searchParams.get("q") || "");

  // when user changes input on search bar
  function handleInputChange(e) {
    console.log("Search input changed:", e.target.value);
    setResults([]);
    setSearchQuery(e.target.value);
    if (searchType.value) {
      setSearchParams({ t: searchType.value, q: e.target.value }, { replace: true });
    } else {
      setSearchParams({ t: "servers", q: e.target.value }, { replace: true });
    }
    // if empty string then execute search for all items
    if (e.target.value === "") {
      executeSearch("", searchType.value, true, loadIndex);
    }
  }

  // when user changes search type in TypeSelector
  function handleTypeChange(_searchType) {
    console.log("Search type changed:", _searchType)
    setResults([]); // reset results
    setSearchType(_searchType); // update searchType
    executeSearch(searchQuery, _searchType.value, true, 0) // execute search
    setSearchParams({ t: _searchType.value, q: searchQuery }, { replace: true }); // update search params
  }

  // when enter is pressed
  function handleSearchEvent(e) {
    if (e.key === "Enter") {
      console.log("Enter pressed");
      executeSearch(searchQuery, searchType.value, true, loadIndex);
      setLoadIndex(0);
    }
  }

  // if loadIndex is changed by loadMore button then search more
  useEffect(() => {
    if (loadIndex > 0) executeSearch(searchQuery, searchType.value, false, loadIndex);
  }, [loadIndex]);
  
  useEffect(() => {
    console.log("searchParams type changed", searchParams.get("t"))
    handleTypeChange(searchTypes.find(type => type.value === searchParams.get("t")))
  }, [searchParams.get("t")])

  return (
    <>
      {/* Top */}
      <div className="-mt-16 mb-8 grid w-screen place-items-center [grid-template-areas:'stack']">
        <div className="h-full w-screen bg-[url(./assets/images/hero-image.png)] bg-cover bg-center [grid-area:stack]" />
        <div className="h-full w-screen bg-zinc-900/90 [grid-area:stack]" />

        <div className="my-24 flex w-full flex-col items-center justify-center px-4 [grid-area:stack] sm:px-8">
          <h1 className="mb-12 px-12 text-center text-5xl font-bold text-zinc-300 sm:text-5xl">
            Find {searchType.name}
          </h1>

          {/* Search Bar */}
          {/* <SearchBox
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            handleInputChange={handleInputChange}
            handleSearchEvent={handleSearchEvent}
            searchType={searchType}
            handleTypeChange={handleTypeChange}
          /> */}
          <div className="grid grid-cols-4 grid-rows-1 gap-0 w-full max-w-5xl">
            <TypeSelector searchTypes={searchTypes} searchType={searchType} setSearchType={handleTypeChange} setLoadIndex={setLoadIndex}/>
            <SearchBox   searchType={searchType} searchQuery={searchQuery} handleInputChange={handleInputChange} handleSearchEvent={handleSearchEvent}  />
          </div>

          <div className="mt-4 rounded-md bg-zinc-800 p-2">
            <TagCloud
              noLabel
              selectedTags={[]}
              unselectedTags={getTags("both") || []}
            />
          </div>
          
        </div>
      </div>

      <div className="mx-auto flex max-w-[1400px] flex-col gap-4 px-4 sm:px-8">
        {/* Results */}
        { searchType.name === "Servers" && <ServersResults loading={loadingResults} results={results} /> }
        { searchType.name === "Developers" && <DevResults loading={loadingResults} results={results} /> }
        { searchType.name === "Assets" && <AssetsResults loading={loadingResults} results={results} /> }

        {/* Load more button */}
        {!noMoreResults ? (
          <div className="mt-8 flex justify-center">
            <Button
              label={
                loadingResults ? (
                  <div className="flex justify-center w-48">
                    <Spinner small />
                  </div>
                ) : (
                  <div className="w-48">
                    <p className="text-zinc-300">
                      Load more
                    </p>
                  </div>
                )
              }
              secondary
              onClick={() => {
                console.log("Load more clicked");
                setLoadIndex(loadIndex + 1);
              }}
            />
          </div>
        ) : (
          <div className="mt-8 flex justify-center">
            {results.length > 0 ? (
              <p className="text-zinc-400">
                Showing all {results.length} {searchType.value.toLowerCase()}{" "}
              </p>
            ) : (
              <p className="text-zinc-400">
                No results found. Try changing your search query or search type!
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
