import React, { useEffect } from 'react'
import Spinner from '../Spinner'

const Button = ({ label, isLoading, disabled, skeleton, secondary, danger, special, full, children, onClick }) => {

  let bgString

  if (secondary) {
    bgString = "bg-zinc-700 border-white/10"
  } else if (special) {
    bgString = "border-white/40 bg-gradient-to-l bg-gradient-to-r from-rose-400 via-fuchsia-500 to-indigo-500 bg-[length:calc(100%+16px)_calc(100%+16px)] bg-[-8px]"
  } else if (danger) {
    bgString = "bg-red-500 border-white/40"
  } else {
    bgString = "bg-blue-500 border-white/40"
  }

  if (isLoading) {
    return (
      <button
      disabled
      className={`${disabled ? 'mouse-events-none' : ''} ${(full ? "w-full":"")} rounded-md transition border duration-100 active:duration-100 ${bgString} py-1 pb-2 px-4 font-semibold min-w-fit`}
    >
      <div className="relative">
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-red-500">
          <Spinner small/>
        </div>
      </div>
      <div className="opacity-0">
        {label}
      </div>
      
    </button>
    )
  } else if (disabled) {
    return (
    <button
      disabled
      className={` ${(full ? "w-full":"")} rounded-md border bg-zinc-700 border-white/10 py-1 pb-2 px-4 font-semibold min-w-fit opacity-40`}
    >
      {label}
    </button>
    ) 
  } else if (skeleton) {
    return (
      <button
        disabled
        className={` ${(full ? "w-full":"")} w-24 h-10 rounded-md bg-zinc-700 border-white/10 py-1 pb-2 px-4 font-semibold min-w-fit animate-pulse`}
      />
    )
  } else {
    return (
      <button
        onClick={() => {onClick()}}
        className={`${disabled ? 'mouse-events-none' : ''} ${(full ? "w-full":"")} rounded-md transition hover:brightness-125 border duration-100 active:duration-100 active:scale-95 ${bgString} py-1 pb-2 px-4 font-semibold min-w-fit `}
      >
        {label}
      </button>
    )
  }
}

export default Button