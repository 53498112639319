import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext }  from '../../App'
import Button from '../../components/inputs/Button'

const Beta = () => {

  const navigate = useNavigate();
  const { user, session, profile, logIn } = useContext(UserContext);

  useEffect(() => {
    console.log("ID", profile?.discord_id)
    if (user) {
      if (profile?.discord_id === '82988532766867456'
        || profile?.discord_id === '377283779724705792'
        || profile?.discord_id === '209892371717029889'
        || profile?.discord_id === '190303445100658699'
        || profile?.discord_id === '650506550066151474'
        || profile?.discord_id === '482725478487162880'
        || profile?.discord_id === '583179712801538078'
      ) {
        navigate('/')
      }
    }
  }, [user, profile, session])
  
  return (
    <div className="absolute top-0 bottom-0 right-0 left-0 bg-zinc-900 z-20">
      <div className="flex justify-center items-center h-full max-w-7xl mx-auto">
        <div className=" bg-zinc-800 rounded-lg p-8 text-center">
            <h1 className="text-5xl font-bold text-blue-500">Welcome</h1>
            <h1 className="text-5xl font-bold text-zinc-300 mb-4">to the FiveBoard beta!</h1>
            <p className="text-zinc-400">If you are authorized, you can log in and start testing!</p>
            <p  className="text-zinc-400 mb-6">Otherwise, if you would like access, message @flohhh on Discord.</p>
            <Button
              full
              label="Log in"
              onClick={() => {
                logIn()
              }}
            />
        </div>
      </div>
    </div>
  )
}

export default Beta